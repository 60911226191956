import Modal from "../../common-components/Modal";

const UpdateTextModal = ({
  showTextModal = true,
  setShowUpdateModel,
  handleUpdateTranscript,
  setIsEditing,
  id,
  transcriptFront,
}) => {
  return (
    <div>
      <Modal
        showModal={showTextModal}
        closeModal={() => setShowUpdateModel(false)}
        modalWidth="w-1/4"
      >
        <h1 className="flex justify-center text-[#192839] font-semibold">
          Are you sure ?
        </h1>
        <h1 className="flex justify-center text-[#40566D] font-normal text-xs">
          All changes will be saved
        </h1>
        <div className="flex justify-end mt-8">
          <button
            onClick={() => {
              setShowUpdateModel(false);
              setIsEditing(false);
            }}
            className="text-[#2950DA] px-4 py-1.5 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleUpdateTranscript(id, transcriptFront);
              setShowUpdateModel(false);
            }}
            className="bg-[#5E48FC] px-6 py-1.5 ml-4 text-xs rounded shadow-md text-white"
          >
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateTextModal;
