import React, { useState } from "react";
import useGetEntityExtraction from "./useGetEntityExtraction";
import { ToastContainer } from "react-toastify";
import ExistingExtractionDocument from "./ExistingExtractionDocument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileUploadModal from "../common/FileUploadModal";
import useGetExistingExtraction from "./useGetExistingExtraction";

const EntityExtraction1 = () => {
  const {
    existingExtractionData = [],
    existingDataLoading,
    setEistingDataLoading,
    filters,
    setFilters,
    handleFileNameClick,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    sortingColumn,
    order,
    getExistingData,
  } = useGetExistingExtraction();
  const { handleExtractionFileUpload, isUplaodLoading } =
    useGetEntityExtraction({ getExistingData });

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  return (
    <div className="sm:px-4 w-full">
      <ToastContainer />
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">
          Extraction & Summarization
        </div>
        <div>
          <button
            onClick={() => setShowFileUploadModal(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Upload Document</span>
          </button>
        </div>
      </div>
      <hr className="sm:-mx-4"></hr>

      {showFileUploadModal ? (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          setShowFileUploadModal={setShowFileUploadModal}
          handleFileUpload={handleExtractionFileUpload}
          isLoading={isUplaodLoading}
          heading="Extraction & Summarization"
          subHeading="Upload the file for Information Extraction"
        />
      ) : null}

      <ExistingExtractionDocument
        existingExtractionData={existingExtractionData}
        existingDataLoading={existingDataLoading}
        setEistingDataLoading={setEistingDataLoading}
        filters={filters}
        setFilters={setFilters}
        handleFileNameClick={handleFileNameClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setOrder={setOrder}
        setSortingColumn={setSortingColumn}
        sortingColumn={sortingColumn}
        order={order}
      />
    </div>
  );
};

export default EntityExtraction1;
