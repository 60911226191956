import React, { useState } from "react";
import avatorImage from "../../Images/avator.png";
import UpdatePassword from "./UpdatePassword";
import { useLocation } from "react-router-dom";

const AccountSetting = () => {
  const location = useLocation();
  const { state = {} } = location || {};
  const { userData = {} } = state;
  const { name, email, phone } = userData || {};
  const [passwordModal, setPasswordModal] = useState(false);
  const setClassname = passwordModal ? "opacity-25" : "opacity-100";

  return (
    <div className="m-4 relative ">
      <div className={setClassname}>
        <div className="flex justify-evenly">
          <h1 className="text-[#192839] font-semibold text-sm">Hi {name} !</h1>
          {/* <button
            className="border bg-red-500 text-white rounded px-1 shadow-md"
            onClick={() => setPasswordModal(true)}
          >
            Update Password
          </button> */}
        </div>
        <div
          style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
          className="bg-white sm:w-1/2 w-full p-4 mx-auto my-4 rounded-md"
        >
          <div className="flex items-center justify-center mb-2  rounded-md">
            <img
              className="p-1 bg-yellow-200 border border-red-500 rounded-full h-full w-1/6"
              src={avatorImage}
              alt="UserImage"
            />
          </div>
          <div className="px-1.5 flex w-full hover:text-yellow-500 ">
            <div className="text-[#192839] font-normal text-sm sm:w-1/2 w-1/3">
              Full name{" "}
            </div>
            <div className="sm:w-1/2 text-[#192839] font-normal text-sm">
              {name}
            </div>
          </div>
          <hr className="m-1" />
          <div className="px-1.5 w-full flex hover:text-yellow-500 ">
            <div className="text-[#192839] font-normal text-sm sm:w-1/2 w-1/3">
              Email{" "}
            </div>
            <div className="sm:w-1/2 text-[#192839] font-normal text-sm">
              {email}
            </div>
          </div>
          <hr className="m-1" />
          <div className="px-1.5 flex w-full hover:text-yellow-500 ">
            <div className="text-[#192839] font-normal text-sm sm:w-1/2 w-1/3">
              Phone{" "}
            </div>
            <div className="sm:w-1/2 text-[#192839] font-normal text-sm">
              {phone}
            </div>
          </div>
        </div>
      </div>
      {passwordModal ? (
        <div className="w-full h-full flex justify-center items-center">
          <UpdatePassword setPasswordModal={setPasswordModal} />
        </div>
      ) : null}
    </div>
  );
};

export default AccountSetting;
