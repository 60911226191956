import React, { useState } from "react";
import Modal from "../../common-components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import useRequestEfilingData from "./hooks/useRequestEfilingData";

const EfilingConfirmationModal = ({
  id,
  isEfilingModal,
  setIsEfilingModal,
}) => {
  const [requestInfo, setRequestInfo] = useState("");
  const handleChange = (e) => {
    const { value } = e.target;
    setRequestInfo(value);
  };
  const { onEfilingData, isEfilingLoading } = useRequestEfilingData();
  return (
    <div>
      <Modal
        showModal={isEfilingModal}
        closeModal={() => setIsEfilingModal(false)}
        modalHeight="min-h-[50%]"
        modalWidth="w-[40%]"
      >
        <div className="text-sm">
          <div className="flex justify-center flex-col items-center">
            <div className="mb-4 -mt-7">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color="#FFC107"
                size="3x"
              />
            </div>
            <div className="text-[#f44336]">
              This document contains defects.
            </div>
            <div>
              Are you sure you want to proceed with e-filing automation ?
            </div>
          </div>
          <div className="m-4">
            <textarea
              value={requestInfo}
              onChange={handleChange}
              name="comment"
              className="focus:outline-none rounded-lg px-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#2D2E33]"
            />
          </div>
          <div className="flex gap-5 mt-5 justify-end">
            <button
              onClick={() => setIsEfilingModal(false)}
              className="border-[#C6C7C9] border rounded-lg px-6"
            >
              No
            </button>
            <button
              disabled={requestInfo.length < 5 || isEfilingLoading}
              onClick={() => onEfilingData(id, requestInfo)}
              className="bg-[#5E48FC] px-6 items-center hover:border-[#D8E3FF] disabled:bg-[#F9FAFB] disabled:border-[#C6C7C9] disabled:text-[#A2A3A7] flex justify-center border border-[#4C6AF7] item-center py-1.5 rounded-lg text-sm text-[#FFFFFF]"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EfilingConfirmationModal;
