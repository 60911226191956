import React from "react";

const Home = () => {
  return (
    <div className="items-end sm:p-60 p-4">
      {/* <div className="text-3xl text-indigo-950 ">
        Transforming the Judicial Process with AI-Powered Solutions
      </div> */}
      {/* <div className="text-xl mt-4">
        Nyaay offers AI-driven solutions for digitizing legal documents and
        transforming the way legal professionals interact with case-related
        information. Our comprehensive suite of tools and services helps
        streamline and optimize the judicial process.
      </div> */}
      <h1 className="flex justify-center text-5xl text-[#5E48FC] font-semibold">
        Yato Dharmastato Jayaḥ
      </h1>
      <h1 className="flex justify-center text-[#40566D] font-normal text-xl">
        whence justice (dharma), thence victory
      </h1>
    </div>
  );
};

export default Home;
