import React from "react";

const DefectedResultsLoader = ({ messages, currentMessageIndex, progress }) => {
  return (
    <div className="w-[80%] mt-5 flex flex-col justify-center items-center">
      {/* Progress Bar */}
      <div className="w-full h-6 bg-gray-200 rounded overflow-hidden relative">
        <span className="absolute w-[100%] flex justify-center items-center z-99">
          {progress}%
        </span>
        <div
          className="h-full bg-green-500 text-center"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      {/* Messages */}
      <div className="my-5 font-semibold flex flex-col justify-center items-center">
        <div className="">
          Step: {currentMessageIndex + 1} out of {messages.length}
        </div>
        <div className="flex">
          {messages[currentMessageIndex]}
          <div className="flex">...</div>
        </div>
      </div>
    </div>
  );
};

export default DefectedResultsLoader;
