import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../common-components/Modal";
const { REACT_APP_API_KEY } = process.env;
const ConfirmModal = ({
  setShowModal,
  showModal,
  getUserList,
  selectedUser,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleUpdateUserRole = async (id, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${REACT_APP_API_KEY}/updateRole/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        // credentials: "include",
        body: JSON.stringify({ authRole: selectedOption }),
      });
      await response.json();
      setIsLoading(false);
      toast.success("Role is updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
      getUserList();
    } catch (error) {
      console.log(error, "error");
      if(error.response.status === 401){
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="absolute shadow rounded top-8 bg-white p-5 m-auto">
      <ToastContainer />
      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        modalWidth="w-1/4"
      >
        <h1 className="flex justify-center text-[#192839] font-semibold">
          Are you sure ?
        </h1>
        <h1 className="flex justify-center text-[#40566D] font-normal text-xs">
          to change the role for {selectedUser?.name} ?
        </h1>
        <div className="text-[#192839] text-sm flex justify-center mt-2">
          <select
            value={selectedOption}
            onChange={handleSelectChange}
            className="border rounded focus:outline-none"
          >
            <option value="" disabled className="text-sm">
              Please select a role
            </option>
            <option value="User">User</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
        <div className="flex justify-end mt-8">
          <button
            onClick={() => setShowModal(false)}
            className="text-[#2950DA] px-4 py-1.5 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleUpdateUserRole(selectedUser?._id, e);
              setShowModal(false);
            }}
            disabled={isLoading}
            className="bg-[#5E48FC] px-6 py-1.5 ml-4 text-xs rounded shadow-md text-white"
          >
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
