import axios from "axios";
import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useExtractionUpdate = ({ id }) => {
  const [extractionData, setExtractionData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const userState = useContext(UserContext);
  const {
    setShowNavigation = () => { },
    setIsLoggedIn = () => { },
  } = userState || {};
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getExtrcationData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getExtractionDataById`,
        {
          params: {
            id: id,
          },
          // withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setExtractionData(response.data._source);
    } catch (err) {
      console.error(err || "Oops somthing went wrong");
      if (err.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
      }
    }
  }, [id, token, navigate, setIsLoggedIn, setShowNavigation]);

  useEffect(() => {
    if(Object.entries(extractionData).length === 0){
      getExtrcationData();
    }
  }, [getExtrcationData, extractionData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (Array.isArray(extractionData[name])) {
      const updatedArray = [...extractionData[name]];
      updatedArray[index] = value;
      setExtractionData((prev) => ({ ...prev, [name]: updatedArray }));
    } else {
      setExtractionData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsEditable(false);
  };

  const handleAddField = (e, fieldName) => {
    e.preventDefault();
    setExtractionData((prevData) => ({
      ...prevData,
      [fieldName]: [...prevData[fieldName], ""],
    }));
  };

  const handleRemoveField = (index, e, fieldName) => {
    e.preventDefault();
    setExtractionData((prevData) => {
      const newData = prevData[fieldName].filter((_, idx) => idx !== index);
      return { ...prevData, [fieldName]: newData };
    });
  };

  const handleExtractionUpdate = async (id) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_KEY}/updateExtraction/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          // credentials: "include",
          body: JSON.stringify(extractionData),
        }
      );
      await response.json();
      if (response.ok) {
        toast.success("Data is successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
      setIsEditable(false);
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
        return;
      }
      toast.error("Oops somthing went wrong !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };

  const {
    Petitioners,
    Respondents,
    Counsels,
    Judges,
    CourtName,
    PetitionType,
    OrderDate,
    CaseNumber,
    FinalJudgement,
    KeyObservations,
    Citations,
    DetailedSummary,
    KeyTimelines,
    Prayer,
  } = extractionData || {};

  const formatedData = {
    Petitioners: Petitioners || [],
    Respondents: Respondents || [],
    Counsels: Counsels || [],
    Judges: Judges || [],
    CourtName: CourtName || "",
    PetitionType: PetitionType || "",
    OrderDate: OrderDate || "",
    CaseNumber: CaseNumber || "",
    FinalJudgement: FinalJudgement || "",
    KeyObservations: KeyObservations || [],
    Citations: Citations || [],
    DetailedSummary: DetailedSummary || "",
    KeyTimelines: KeyTimelines || [],
    Prayer: Prayer || [],
  };

  return {
    handleExtractionUpdate,
    isEditable,
    handleInputChange,
    handleEdit,
    handleSave,
    handleAddField,
    handleRemoveField,
    extractionData,
    formatedData,
  };
};

export default useExtractionUpdate;