import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useGetUsers from "./useGetUsers";
import ConfirmModal from "./ConfirmModal";
import Pagination from "../../common-components/Pagination";
import Dskeleton from "../../common-components/Dskeleton";
import { startCase } from "lodash";
import { UserContext } from "../Routing";
import { ToastContainer } from "react-toastify";

const UserManagement = () => {
  const userState = useContext(UserContext);
  const { userDetails = {} } = userState || {};
  const { userData } = userDetails;
  const { authRole } = userData;
  const {
    userList = {},
    isLoading,
    filters,
    currentPage,
    setCurrentPage,
    getUserList,
    setFilters,
    setIsLoading,
  } = useGetUsers();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { users = [], totalCount, totalPages } = userList;
  const { searchInput = "" } = filters || {};

  const handleSearchTermChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      searchInput: event.target.value || undefined,
    }));
  };

  return (
    <>
      <div className="py-2 mx-6">
        <ToastContainer />
        <div className="text-[#40566D] text-xl font-medium">
          Users (Manage Accounts)
        </div>
        <hr className="-mx-6 my-2"></hr>
        <div className="flex my-4">
          <input
            type="search"
            placeholder="Search by Name / Email / Role "
            value={searchInput}
            onChange={handleSearchTermChange}
            required
            className="w-1/4 font-normal text-sm p-1.5 border border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="bg-white max-h-screen relative flex flex-col">
          <div className="flex-grow overflow-x-auto">
            <table className="table-fixed border  w-full">
              <thead>
                <tr className="bg-[#6C849D1F] text-[#192839] font-medium text-sm">
                  <th className="py-2 px-4 text-left">
                    <div className="flex items-center">
                      <span className="font-semibold text-sm pr-1 cursor-pointer">
                        Name
                      </span>
                    </div>
                  </th>
                  <th className="py-2 px-4 text-left">
                    <div className="flex items-center">
                      <span className="font-semibold cursor-pointer">
                        Email
                      </span>
                    </div>
                  </th>

                  <th className="py-2 px-4 text-left">
                    <div className="flex items-center">
                      <span className="font-semibold cursor-pointer">Role</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Dskeleton columns={3} />
                ) : (
                  <>
                    {users?.length > 0 ? (
                      users.map((data, index) => (
                        <tr
                          key={index}
                          className="hover:bg-gray-200 bg-white border-b border-grey-100"
                        >
                          <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                            {data?.name || "--"}
                          </td>
                          <td className="py-1.5 ps-4 text-[#192839] font-normal text-sm">
                            {data?.email || "--"}
                          </td>
                          <td className="ps-4 mt-2 flex item-center w-full text-[#192839] font-normal text-sm">
                            <div className="w-1/2">
                              {startCase(data?.authRole) || "--"}
                            </div>
                            <div className="-mt-1">
                              {authRole === "SuperAdmin" ? (
                                <button
                                  id={data?._id}
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedUser(data);
                                  }}
                                  className="bg-[#5E48FC] px-2 py-1.5 ml-4 text-xs rounded shadow-md text-white"
                                >
                                  Change Role
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="bg-white p-3">
                          <h1 className="rounded shadow-md p-3 items-center text-center">
                            No Results Found
                          </h1>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="self-end p-4">
            <Pagination
              setIsLoading={setIsLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={totalCount}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
      {showModal ? (
        <div className="flex justify-center">
          <ConfirmModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedUser={selectedUser}
            getUserList={getUserList}
          />
        </div>
      ) : null}
    </>
  );
};

export default UserManagement;
