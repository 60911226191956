import React from "react";
import Modal from "../../common-components/Modal";
import "react-toastify/dist/ReactToastify.css";

const UpdateModal = ({
  showUpdateModal,
  setShowUpdateModal,
  handleUpdate,
  id,
}) => {
  return (
    <div>
      <Modal
        closeModal={() => setShowUpdateModal(false)}
        showModal={showUpdateModal}
        modalWidth="w-1/4"
      >
        <h1 className="flex justify-center text-[#192839] font-semibold">
          Are you sure ?
        </h1>
        <h1 className="flex justify-center text-[#40566D] font-normal text-xs">
          All changes will be saved
        </h1>
        <div className="flex justify-end mt-8">
          <button
            onClick={() => setShowUpdateModal(false)}
            className="text-[#2950DA] px-4 py-1.5 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleUpdate(id);
              setShowUpdateModal(false);
            }}
            className="bg-[#5E48FC] px-6 py-1.5 ml-4 text-xs rounded shadow-md text-white"
          >
            Update
          </button>
        </div>
      </Modal>
      {/* </div> */}
    </div>
  );
};

export default UpdateModal;
