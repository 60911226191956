import React from "react";
import InputLayout from "./InputLayout";
function ProvisionOfLaw({
  isEditable,
  provisionsOfLawDetails = {},
  setProvisionOfLawsData = () => {},
}) {
  return (
    <div className="flex justify-center py-4">
      <form className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5">
        <div className="flex">
          <div className="text-[#192839] text-base font-semibold">
            Provision of Law
          </div>
        </div>
        <hr className="my-4"></hr>

        <InputLayout
          isEditable={isEditable}
          petitionerData={provisionsOfLawDetails}
          setPetitionerData={setProvisionOfLawsData}
        />
      </form>
    </div>
  );
}

export default ProvisionOfLaw;
