import React, { useState } from "react";
import useUploadSpeech from "./useUploadSpeech";
import { ToastContainer } from "react-toastify";
import VideoList from "./VideoList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileUploadModal from "../common/FileUploadModal";
import useGetVideoList from "./useGetVideoList";

const SpeechToText = () => {
  const {
    videoList,
    isVideoListLoading,
    setIsVideoListLoading,
    filters,
    setFilters,
    handleFileNameClick,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    order,
    sortingColumn,
    getVideoList,
  } = useGetVideoList();

  const { handleSpeechFileUpload, isUplaodLoading } = useUploadSpeech({
    getVideoList,
  });
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  return (
    <div className="sm:px-4 w-full">
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">
          Audio Transcription
        </div>
        <div>
          <button
            onClick={() => setShowFileUploadModal(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Upload Video</span>
          </button>
        </div>
      </div>
      <hr className="sm:-mx-4"></hr>
      {/* <div className="flex w-full px-2 justify-evenly">
        <button
          className={`${
            activeSubTab === "Upload"
              ? "text-white p-2 w-full  bg-[#4D64DC]"
              : "bg-white hover:bg-[#7384db] hover:text-white p-2 w-full text-[#4D64DC]"
          } text-lg rounded-3xl border-[1px] border-[#4D64DC4A] font-medium w-[45%]`}
          onClick={() => {
            setActiveSubTab("Upload");
          }}
        >
          New Upload
        </button>
        <button
          className={`${
            activeSubTab === "Existing Video"
              ? "text-white p-2 w-full  bg-[#4D64DC]"
              : "bg-white hover:bg-[#7384db] hover:text-white p-2 w-full text-[#4D64DC]"
          } text-lg rounded-3xl border-[1px] border-[#4D64DC4A] font-medium w-[45%]`}
          onClick={() => {
            setActiveSubTab("Existing Video");
          }}
        >
          Existing Video
        </button>
      </div>
      {activeSubTab === "Upload" && (
        <>
          {isUplaodLoading ? (
            <h1 className="m-auto">Uploading....</h1>
          ) : (
            <UploadDocument handleFileUpload={handleSpeechFileUpload} />
          )}
        </>
      )} */}
      {/* {activeSubTab === "Existing Video" && <VideoList />} */}
      <ToastContainer />
      {showFileUploadModal ? (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          setShowFileUploadModal={setShowFileUploadModal}
          handleFileUpload={handleSpeechFileUpload}
          isLoading={isUplaodLoading}
          heading="Audio Transcription"
          subHeading="Upload the file for Audio Transcription"
        />
      ) : null}
      <VideoList
        videoList={videoList}
        isVideoListLoading={isVideoListLoading}
        setIsVideoListLoading={setIsVideoListLoading}
        filters={filters}
        setFilters={setFilters}
        handleFileNameClick={handleFileNameClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setOrder={setOrder}
        setSortingColumn={setSortingColumn}
        order={order}
        sortingColumn={sortingColumn}
      />
    </div>
  );
};

export default SpeechToText;
