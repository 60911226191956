// import { useContext } from "react";
import { toast } from "react-toastify";
// import { UserContext } from "../Routing";
const { REACT_APP_API_KEY } = process.env;

const useUpdateTextData = () => {

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleUpdateTranscript = async (id, fetchedTranscript) => {
    const dataToSend = {
      transcript: fetchedTranscript,
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_KEY}/updateRealTimeSTTTextData/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          params: {
            id: id,
          },
          // credentials: "include",
          body: JSON.stringify(dataToSend),
        }
      );
      await response.json();
      toast.success("Data is successfully updated", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error, "error");
      if(error.response.status === 401){
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        return;
      }
      toast.error("Please upload valid file", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
  return {handleUpdateTranscript};
};

export default useUpdateTextData;
