import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useUploadRectifiedPage = ({
  id,
  rectifiedPageNo,
  selectedCourt,
  getSelectedData,
}) => {
  const [isRectifiedUploading, setIsRectifiedUploading] = useState(false);

  const onRectifiedUpload = async (selectedFiles) => {
    setIsRectifiedUploading(true);

    const formData = new FormData();
    formData.append("id", id);
    formData.append("pageNo", rectifiedPageNo);
    formData.append("selectedCourt", selectedCourt);

    Array.from(selectedFiles).forEach((file) => {
      console.log(file, "inside loop");
      formData.append("file", file);
    });
    // formData.append("file", selectedFiles[0]);

    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      await axios.post(
        `${REACT_APP_API_KEY}/defectAnalysis/rectifyPageDefect`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("File uploaded successfully");
      setTimeout(() => {
        getSelectedData();
        for (let i = 1; i <= 2; i++) {
          setTimeout(() => {
            getSelectedData();
          }, i * 10000); // Each call 10 seconds apart
        }
      }, 500);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsRectifiedUploading(false);
  };
  return { onRectifiedUpload, isRectifiedUploading };
};

export default useUploadRectifiedPage;
