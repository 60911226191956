import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

const useRequestEfilingData = () => {
  const [isEfilingLoading, setIsEfilingLoading] = useState(false);

  const onEfilingData = async (id, requestInfo) => {
    setIsEfilingLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      await axios.post(
        `${REACT_APP_API_KEY}/efiling/uploadFileAuto`,
        {
          id: id,
          comment: requestInfo,
        }, // Payload in body
        {
          headers: {
            "Content-Type": "application/json", // Send as JSON
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(
        "Your request for E-Filing automation is successfully sent"
      );
      setTimeout(() => {
        const encodedData = { id: id };
        const navigateURL = `${REACT_APP_BASE_URL}/EfilingHistory?data=${JSON.stringify(
          encodedData
        )}`;
        window.open(navigateURL, "_self");
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsEfilingLoading(false);
  };

  return {
    onEfilingData,
    isEfilingLoading,
  };
};

export default useRequestEfilingData;
