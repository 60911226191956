import React from "react";
function Navbuttons({ activeTab, setActiveTab }) {
  const handleActiveTab = (value) => {
    setActiveTab(value);
  };
  return (
    <div className="flex w-full items-center my-4">
      <div className="w-[34%] flex justify-center items-center">
        <button
          onClick={() => setActiveTab("document_details")}
          className={
            activeTab === "document_details"
              ? "text-[#305EFF] border-b py-1.5 w-full border-[#305EFF]"
              : "text-[#768EA7] border-b w-full py-1.5 border-gray-200"
          }
        >
          Document Details
        </button>
      </div>
      <div className="w-1/3 flex justify-center items-center">
        <button
          onClick={() => setActiveTab("summary")}
          className={
            activeTab === "summary"
              ? "text-[#305EFF] border-b py-1.5 w-full border-[#305EFF]"
              : "text-[#768EA7] border-b w-full py-1.5 border-gray-200"
          }
        >
          Summary
        </button>
      </div>
      <div className="w-1/3 flex justify-center items-center">
        <button
          onClick={() => {
            handleActiveTab("View_Document");
          }}
          className={
            activeTab === "View_Document"
              ? "text-[#305EFF] border-b py-1.5 w-full border-[#305EFF]"
              : "text-[#768EA7] border-b w-full py-1.5 border-gray-200"
          }
        >
          View Document
        </button>
      </div>
    </div>
  );
}

export default Navbuttons;
