import React, { useState } from "react";
import useGetEfiling from "./useGetEfiling";
import { ToastContainer } from "react-toastify";
import ExistingEfilingDoc from "./ExistingEfilingDoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileUploadModal from "../common/FileUploadModal";
import useGetExistingEfiling from "./useGetExistingEfiling";
function Efiling() {
  const {
    existingEfilingData = [],
    existingDataLoading,
    setEistingDataLoading,
    filters,
    setFilters,
    handleFileNameClick,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    sortingColumn,
    order,
    getExistingData,
  } = useGetExistingEfiling();
  const { handleEfilingFileUpload, isUplaodLoading } = useGetEfiling({
    getExistingData,
  });
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  return (
    <div className="sm:px-4 w-full">
      <ToastContainer />
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">
          E-filing Automation
        </div>
        <div>
          <button
            onClick={() => setShowFileUploadModal(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Upload Document</span>
          </button>
        </div>
      </div>

      {showFileUploadModal ? (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          setShowFileUploadModal={setShowFileUploadModal}
          handleFileUpload={handleEfilingFileUpload}
          isLoading={isUplaodLoading}
          heading="E-Filing"
          subHeading="Upload the file for E-Filing"
        />
      ) : null}
      <hr className="sm:-mx-4"></hr>
      <ExistingEfilingDoc
        existingEfilingData={existingEfilingData}
        setEistingDataLoading={setEistingDataLoading}
        existingDataLoading={existingDataLoading}
        filters={filters}
        setFilters={setFilters}
        handleFileNameClick={handleFileNameClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setOrder={setOrder}
        setSortingColumn={setSortingColumn}
        sortingColumn={sortingColumn}
        order={order}
      />
    </div>
  );
}

export default Efiling;
