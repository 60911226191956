import React, { useContext, useState } from "react";
import "tailwindcss/tailwind.css";
import useForgotPassword from "./useForgotPassword";
import ForgotPage from "./ForgotPage";
import { ToastContainer } from "react-toastify";
// import nyaayLogo from "../../Images/Main.png";
import { UserContext } from "../Routing";

const Login1 = ({ setShowSignUpPage = () => {} }) => {
  const userState = useContext(UserContext);
  const { userDetails = {} } = userState || {};
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const {
    forgotPassword,
    resetPassword,
    handleForgotInputChange,
    forgotData,
    forgotFormData,
    isForgotPasswordLoading,
    isResetPaaswordLoading,
    handleSubmit,
    isLoginAPILoading,
  } = useForgotPassword({
    formData,
  });
  const { status } = forgotData || {};

  return (
    <div
      className="rounded-lg pb-8 w-11/12 sm:w-3/5  m-auto"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      {/* <div className="flex justify-center">
        <img className="w-1/2" src={nyaayLogo} alt="UserImage" />
      </div> */}
      {status === 200 ? (
        <ForgotPage
          resetPassword={resetPassword}
          forgotFormData={forgotFormData}
          forgotData={forgotData}
          handleForgotInputChange={handleForgotInputChange}
          isResetPaaswordLoading={isResetPaaswordLoading}
        />
      ) : (
        <div>
          <div className="py-3 ps-5">
            <h1 className="text-xl">Welcome back!</h1>
            <h1 className="text-gray-500 text-sm">Login with your email.</h1>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit} className="px-6">
            <div className="py-4">
              <label className="text-gray-700">Email</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                required
                className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
              />
              {userDetails?.error === "Invalid Credientilas" && (
                <p className="text-red-500 w-full">Invalid credentials</p>
              )}
            </div>
            <div className="mb-4">
              <label className="text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter your password"
                required
                className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
              />
              {userDetails?.error === "Invalid Credientilas" && (
                <p className="text-red-500 w-full">Invalid credentials </p>
              )}
            </div>
            <div className="flex justify-between item-center font-light">
              <div className="flex item-center gap-1">
                <div>
                  <input type="checkbox" />
                </div>
                <p>Remember me</p>
              </div>
              <div>
                <button
                  className="underline w-full mb-4 hover:text-yellow-500"
                  type="button"
                  onClick={forgotPassword}
                  disabled={isForgotPasswordLoading}
                >
                  {isForgotPasswordLoading ? "Loading..." : "Forgot password?"}
                </button>
                <ToastContainer />
              </div>
            </div>
            <div className="my-4 -mx-6">
              <hr></hr>
            </div>
            <button
              disabled={isLoginAPILoading}
              type="submit"
              className="disabled:bg-gray-500 bg-[#5E48FC] hover:bg-indigo-700 text-white w-full rounded p-2 mb-4"
            >
              {isLoginAPILoading ? "Loading..." : "Login"}
            </button>
            <ToastContainer />
          </form>
        </div>
      )}
      <div className="flex justify-center font-light">
        <div className="">Don't have an account?</div>
        <button
          className="underline  hover:text-yellow-500 ml-2"
          onClick={() => setShowSignUpPage(true)}
        >
          Create One
        </button>
      </div>
    </div>
  );
};

export default Login1;
