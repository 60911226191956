import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../Routing";
const { REACT_APP_API_KEY } = process.env;

const useUploadAudio = () => {
  const userState = useContext(UserContext);
  const { userDetails } = userState || {};
  const { userData = {} } = userDetails || {};
  const { name, email } = userData || {};
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleAudioFileUpload = async (filename, id, transcript) => {
    setIsUploadLoading(true);
    const formData = new FormData();
    formData.append("filename", filename);
    formData.append("email", email);
    formData.append("name", name);
    formData.append("file_id", id);
    formData.append("transcript", transcript);
    try {
      const response = await axios(
        {
          url:`${REACT_APP_API_KEY}/RealTimeSTT/uploadAudio`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          // credentials: "include",
          data: formData,
        }
      );
      toast.success("Audio is uploaded successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return response.data.id;  // Return the ID from the response
    } catch (error) {
      console.log(error, "error");
      toast.error("Please upload a valid file", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return null;
    } finally {
      setIsUploadLoading(false);
    }
  };

  return {
    handleAudioFileUpload,
    isUploadLoading,
  };
};

export default useUploadAudio;
