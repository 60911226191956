import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
// import nyaayLogo from "../../Images/Main.png";
const { REACT_APP_API_KEY } = process.env;

function Signup({ setShowSignUpPage = () => {} }) {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const [verifyUserForm, setVerifyUserForm] = useState({
    email: "",
    emailOtp: "",
  });
  const [isSignUpAPILoading, setIsSignUpAPILoading] = useState(false);
  const [isVerifyOTPLoading, setIsVerifyOTPLoading] = useState(false);

  const handleVerifyInputChange = (e) => {
    const { name, value } = e.target;
    setVerifyUserForm({ ...verifyUserForm, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSignUpAPILoading(true);
    try {
      const response = await fetch(`${REACT_APP_API_KEY}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const res = await response.json();
      setVerifyUserForm((prev) => ({ ...prev, email: res?.email }));
      setData(res);
      if (response.ok) {
        console.log("User created successfully:", res.message);
      } else {
        console.error("Error creating user:", res.message);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
    setIsSignUpAPILoading(false);
  };

  const handleVerifyUser = async (e) => {
    e.preventDefault();
    setIsVerifyOTPLoading(true);
    try {
      const response = await axios.post(`${REACT_APP_API_KEY}/verify-otp`, {
        email: verifyUserForm?.email,
        otp: verifyUserForm?.emailOtp,
      });
      if (response.status === 201) {
        toast(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        console.log("User created successfully:", response?.data?.message);
        setShowSignUpPage(false);
      }
    } catch (error) {
      console.log(error, "error");
      toast(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    setIsVerifyOTPLoading(false);
  };

  return (
    <div
      className="rounded-lg px-10 pb-8 w-3/5 shadow-md m-auto"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex justify-center pt-6">
        {/* <img src={nyaayLogo} className="w-1/2" alt="nyaay-logo" /> */}
      </div>
      {data?.status === 201 ? (
        <form onSubmit={handleVerifyUser}>
          <h2 className="text-red-500 mb-2"> {data?.message}</h2>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={verifyUserForm.email}
              onChange={handleVerifyInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
              disabled
            />
          </div>
          <div className="mb-3">
            <label>OTP</label>
            <input
              type="text"
              name="emailOtp"
              value={verifyUserForm.emailOtp}
              onChange={handleVerifyInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
              placeholder="Enter your Email OTP"
            />
          </div>
          <button
            type="submit"
            disabled={isVerifyOTPLoading}
            className="disabled:bg-gray-500 w-full mb-2 bg-indigo-500 text-white p-1.5 rounded-xl hover:bg-indigo-700 focus:outline-none focus:shadow-outline-indigo"
          >
            {isVerifyOTPLoading ? "Loading..." : "Verify User"}
          </button>
          <ToastContainer />
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
            />
            {data?.error === "Email alraedy exist" && (
              <p className="text-red-500 w-full">Email alraedy exist</p>
            )}
          </div>
          <div className="mb-3">
            <label>Phone</label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
            />
            {data?.error === "Phone number already exit" && (
              <p className="text-red-500 w-full">Phone number already exit</p>
            )}
          </div>
          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
            />
          </div>
          <div className="mb-3">
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleInputChange}
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              required
            />
            {data?.error === "password and confirm password are not same" && (
              <p className="text-red-500 w-full">
                password and confirm password are not same
              </p>
            )}
          </div>
          <button
            disabled={isSignUpAPILoading}
            type="submit"
            className="w-full mb-2 bg-[#4D64DC] disabled:bg-gray-400 text-white p-1.5 rounded-xl hover:bg-indigo-700 focus:outline-none focus:shadow-outline-indigo"
          >
            {isSignUpAPILoading ? "Loading..." : "Signup"}
          </button>
        </form>
      )}
      <div className="flex justify-center font-light">
        <div className="">Already have an account?</div>
        <button
          className="underline  hover:text-yellow-500 ml-2"
          onClick={() => setShowSignUpPage(false)}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Signup;
