import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useGetSpeechDataById = ({ id }) => {
  const [speechData, setSpeechData] = useState({});

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getSpeechData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getSpeechTextDataById`,
        {
          params: {
            id: id,
          },
          // withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSpeechData(response.data);
    } catch (err) {
      console.error(err || "Oops somthing went wrong");
      if(err.response.status === 401){
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }
  }, [id, token]);

  useEffect(() => {
    getSpeechData();
  }, [getSpeechData]);

  return {
    speechData,
  };
};

export default useGetSpeechDataById;
