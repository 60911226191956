import React from "react";
// import useGetVideoList from "./useGetVideoList";
import Dskeleton from "../../common-components/Dskeleton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Pagination from "../../common-components/Pagination";
import { Arrow } from "../../common-components/Arrow";
import RenderTooltipComponent from "../../common-components/RenderTooltipComponent";
const dateFormat = "dd/MM/yyyy";

const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY | HH:mm");
};

const VideoList = ({
  videoList,
  isVideoListLoading,
  setIsVideoListLoading,
  filters,
  setFilters,
  handleFileNameClick,
  currentPage,
  setCurrentPage,
  setOrder,
  setSortingColumn,
  order,
  sortingColumn,
}) => {
  // const {
  //   videoList,
  //   isVideoListLoading,
  //   setIsVideoListLoading,
  //   filters,
  //   setFilters,
  //   handleFileNameClick,
  //   currentPage,
  //   setCurrentPage,
  //   setOrder,
  //   setSortingColumn,
  //   order,
  //   sortingColumn,
  // } = useGetVideoList();

  const { searchInput = "", dates } = filters || {};
  const [startDate, endDate] = dates || [];
  const { searchedData = [], totalRecords, totalPages } = videoList;

  const handleDateChange = (dates) => {
    setFilters((prev) => ({ ...prev, dates: dates || undefined }));
  };

  const handleSearchTermChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      searchInput: event.target.value || undefined,
    }));
  };

  const handleColumnHeaderClick = (headerColumn) => {
    if (sortingColumn === headerColumn) {
      setOrder(order === "desc" ? "asc" : "desc");
    } else setOrder("asc");
    setIsVideoListLoading(true);
    setSortingColumn(headerColumn);
  };

  return (
    <div className="my-4 mx-2 flex flex-col h-screen">
      <div className="flex items-center justify-between mb-4">
        <div className="w-3/5">
          <input
            type="search"
            placeholder="Search by File / Uploaded by Name"
            value={searchInput}
            onChange={handleSearchTermChange}
            required
            className="w-full font-normal text-sm p-1.5 border border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="ml-4">
          <DatePicker
            id="dateRange"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable
            showMonthDropdown
            showYearDropdown
            dateFormat={dateFormat}
            placeholderText="Select Date Range"
            className="p-1.5 border text-sm  border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
      </div>
      <div className="bg-white max-h-screen relative flex flex-col">
        <div className="flex-grow overflow-x-auto">
          <table className="table-fixed border  w-full">
            <thead>
              <tr className="bg-[#6C849D1F] text-[#192839] font-medium text-sm">
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="font-semibold pr-1 cursor-pointer"
                      onClick={() => handleColumnHeaderClick("filename")}
                    >
                      Filename
                    </span>
                    <Arrow
                      onClick={() => handleColumnHeaderClick("filename")}
                      headerColumn="filename"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="font-semibold cursor-pointer">
                      Status{" "}
                    </span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="cursor-pointer pr-1 font-semibold"
                      onClick={() => handleColumnHeaderClick("uploadedAt")}
                    >
                      Uploaded At
                    </span>

                    <Arrow
                      onClick={() => handleColumnHeaderClick("uploadedAt")}
                      headerColumn="uploadedAt"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>

                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="cursor-pointer pr-1 font-semibold"
                      onClick={() => {
                        handleColumnHeaderClick("uploadedBy");
                      }}
                    >
                      Uploaded By
                    </span>
                    {/* <Arrow
                      onClick={() => handleColumnHeaderClick("uploadedBy")}
                      headerColumn="uploadedBy"
                      order={order}
                      activeColumn={sortingColumn}
                    /> */}
                  </div>
                </th>
              </tr>
            </thead>
            {isVideoListLoading ? (
              <Dskeleton columns={4} />
            ) : (
              <>
                {searchedData?.length > 0 ? (
                  <tbody>
                    {searchedData?.map((data, index) => {
                      const { _source } = data;
                      return (
                        <tr
                          key={index}
                          className="hover:bg-gray-200 bg-white border-b-2 border-grey-100"
                        >
                          <td className="py-2 px-4 font-normal text-sm">
                            <button
                              className={
                                _source?.status === "In Progress"
                                  ? "text-black"
                                  : "text-[#2950DA] hover:underline"
                              }
                              onClick={() => handleFileNameClick(data?._id)}
                            >
                              <RenderTooltipComponent
                                content={_source?.filename}
                                maxLength={24}
                                maxWidth={600}
                              />
                            </button>
                          </td>
                          <td className="py-2 px-4 font-normal text-sm">
                            <span
                              className={
                                data._source["status"] === "In Progress"
                                  ? "py-0.5 px-1.5 bg-[#D92D2017] rounded-xl text-[#D92D20]"
                                  : "py-0.5 px-1.5 bg-[#00A25117] text-[#008743] rounded-xl"
                              }
                            >
                              {_source?.status === "In Progress"
                                ? "In Progress"
                                : "Completed"}
                            </span>
                          </td>
                          <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                            {formatDateTime(_source?.uploadedAt) || "--"}
                          </td>
                          <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                            {_source?.uploadedBy || "--"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan="4"
                        className="bg-white rounded shadow-md p-3"
                      >
                        <h1>No Results Found</h1>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            )}
          </table>
        </div>
        <div className="self-end p-4">
          <Pagination
            setIsLoading={setIsVideoListLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalRecords}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoList;
