import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import useGetExistingDocument from "./useGetExistingDocument";
import Dskeleton from "../../common-components/Dskeleton";
import Pagination from "../../common-components/Pagination";
import RenderTooltipComponenT from "../../common-components/RenderTooltipComponent";
import { ToastContainer } from "react-toastify";
const dateFormat = "dd/MM/yyyy";
const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY | HH:mm");
};

const ExistingDocumentAnalysis = ({
  existingData = [],
  existingDataLoading,
  setEistingDataLoading,
  filters,
  setFilters,
  handleFileNameClick,
  currentPage,
  setCurrentPage,
}) => {
  // const {
  //   existingData = [],
  //   existingDataLoading,
  //   setEistingDataLoading,
  //   filters,
  //   setFilters,
  //   handleFileNameClick,
  //   currentPage,
  //   setCurrentPage,
  // } = useGetExistingDocument();
  const { searchInput = "", dates } = filters || {};
  const [startDate, endDate] = dates || [];
  const { searchedData = [], totalRecords, totalPages } = existingData;

  const handleDateChange = (dates) => {
    setEistingDataLoading(true);
    setFilters((prev) => ({ ...prev, dates: dates || undefined }));
  };

  const handleSearchTermChange = (event) => {
    setEistingDataLoading(true);
    setFilters((prev) => ({
      ...prev,
      searchInput: event.target.value || undefined,
    }));
  };

  // Make PDFs clickable on right click
  const handleRightClick = (e, id) => {
    e.preventDefault();
    handleFileNameClick(id);
  };

  return (
    <div className="my-4 mx-2 flex flex-col">
      <ToastContainer />
      <div className="flex items-center justify-between mb-4">
        <div className="w-3/5">
          <input
            type="search"
            placeholder="Search by File / Email / Court / Petitioner / Attorney / Court / Respondent Name"
            value={searchInput}
            onChange={handleSearchTermChange}
            required
            className="w-full font-normal text-sm p-1.5 border border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="ml-4">
          <DatePicker
            id="dateRange"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable
            showMonthDropdown
            showYearDropdown
            dateFormat={dateFormat}
            placeholderText="Select Date Range"
            className="p-1.5 border text-sm  border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
      </div>
      <div className="bg-white max-h-screen relative flex flex-col">
        <div className="flex-grow overflow-x-auto">
          <table className=" border  w-full">
            <thead>
              <tr className="bg-[#6C849D1F] text-[#192839] font-medium text-sm">
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="font-semibold text-sm pr-1 cursor-pointer">
                      Filename
                    </span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Status
                    </span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Uploaded Date
                    </span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Uploaded By
                    </span>
                  </div>
                </th>

                {/* <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Court Name
                    </span>
                  </div>
                </th> */}

                {/* <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Petitioner Name
                    </span>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody className="w-full">
              {existingDataLoading ? (
                <Dskeleton columns={5} />
              ) : searchedData.length > 0 ? (
                searchedData.map((data, index) => (
                  <tr
                    key={index}
                    className="hover:bg-[#E7EAF7] w-full bg-white border-b border-grey-100"
                  >
                    <td className="py-2 px-4 font-normal text-sm">
                      <button
                        className="text-[#2950DA] hover:underline"
                        onClick={() => handleFileNameClick(data?._id)}
                        onContextMenu={(e) => handleRightClick(e, data?._id)}
                      >
                        <RenderTooltipComponenT
                          content={data?._source?.filename}
                          maxLength={50}
                          maxWidth={600}
                        />
                      </button>
                    </td>
                    <td className="py-2 px-4 font-normal text-sm">
                      <span
                        className={
                          data._source["status"] === "In Progress"
                            ? "py-0.5 px-1.5 bg-[#D92D2017] rounded-xl text-[#D92D20]"
                            : "py-0.5 px-1.5 bg-[#00A25117] text-[#008743] rounded-xl"
                        }
                      >
                        {data._source["status"]}
                      </span>
                    </td>
                    <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                      {formatDateTime(data?._source?.uploadedAt)}
                    </td>
                    <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                      <RenderTooltipComponenT
                        content={data?._source?.uploadedBy}
                        maxLength={20}
                        maxWidth={600}
                      />
                    </td>
                    {/* <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                      <RenderTooltipComponenT
                        content={data?._source?.metaData?.courtName}
                        maxLength={20}
                        maxWidth={600}
                      />
                    </td> */}
                    {/* <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                      <RenderTooltipComponenT
                        content={data?._source?.metaData?.petitioner}
                        maxLength={20}
                        maxWidth={600}
                      />
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="bg-white p-3">
                    <h1 className="rounded shadow-md p-3 items-center text-center">
                      No Results Found
                    </h1>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="self-end p-4">
          <Pagination
            setIsLoading={setEistingDataLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalRecords}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default ExistingDocumentAnalysis;
