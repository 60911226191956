import React, { useState } from "react";
import Model from "../../common-components/Modal";
import GetRealSTT from "./GetRealSTT";
import useUploadAudio from "./useUploadAudio";

const AudioRecordModel = ({
  showAudioRecordModel = false,
  setShowAudioRecordModel = () => {},
  handleFileUpload = () => {},
  currentTab = "",
  isLoading = false,
  heading = "",
  subHeading = "",
}) => {
  const [filename, setFilename] = useState("");
  const [language, setLanguage] = useState("en");
  const [transcript, setTranscript] = useState("");
  const [fileId, setFileId] = useState("");
  const { handleAudioFileUpload, isUplaodLoading } = useUploadAudio();

  const handleSave = (e) => {
    e.preventDefault();
    handleAudioFileUpload(filename, fileId, transcript);
    // if (audioBlob) {
    //   const file = new File([audioBlob], "recording.webm", { type: "audio/webm" });
    //   handleFileUpload([file]);
    //   setAudioBlob(null);
    // }
    // setFileList([]);
    setTimeout(() => {
      setShowAudioRecordModel(false);
    }, 1000);
  };

  let disabledConditions = isLoading || transcript==="" || fileId==="" || filename==="" || isUplaodLoading ;

  return (
    <div>
      <Model
        showModal={showAudioRecordModel}
        closeModal={() => {
          setShowAudioRecordModel(false);
        }}
        heading={heading}
        subHeading={subHeading}
      >
        <GetRealSTT 
          language={language} 
          setLanguage={setLanguage}  
          transcript={transcript} 
          setTranscript={setTranscript}
          fileId={fileId}
          setFileId={setFileId}
          filename={filename}
          setFilename={setFilename}
        />
        <hr className="-mx-5 my-3" />
        <div className="flex justify-end">
          <button
            disabled={disabledConditions}
            onClick={(e) => {
              handleSave(e);
            }}
            className="bg-[#5E48FC] hover:bg-[#5E48FCCC] text-sm px-4 disabled:text-gray-500 py-1 rounded text-[#FFFFFF] disabled:bg-[#6C849D17]"
          >
            {isLoading ? "Uploading..." : "Upload"}
          </button>
        </div>
      </Model>
    </div>
  );
};

export default AudioRecordModel;
