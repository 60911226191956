import axios from "axios";
import { useContext, useState } from "react";
import { UserContext } from "../Routing";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useDefectAnalysisData = ({ getExistingData = () => {} }) => {
  const userState = useContext(UserContext);
  const { userDetails } = userState || {};
  const [data, setData] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedDefectsPage, setSelectedDefectsPage] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [defectsCategory, setDefectsCategory] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState("");
  const { metaData: metaDataField } = updateData || {};
  const { courtName, judge, petitioner, respondent, attorney } =
    metaDataField || {};
  const [metaData, setMetaData] = useState({
    courtName: courtName || "",
    judge: judge || "",
    petitioner: petitioner || "",
    respondent: respondent || "",
    attorney: attorney || "",
  });

  const { userData = {} } = userDetails || {};
  const { name, email } = userData || {};

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleFileUpload = async (selectedFiles) => {
    setIsLoading(true);
    setShowPopup(false);
    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("email", email);
    formData.append("name", name);
    formData.append("defectsCategory", [...defectsCategory]);
    formData.append("selectedCourt", selectedCourt);

    try {
      const response = await axios.post(
        `${REACT_APP_API_KEY}/defectAnalysis/uploadFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          // withCredentials: true,
        }
      );
      setSelectedCourt("");
      toast.success(response?.data?.message || "File uploaded Successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        getExistingData();
      }, 800);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        return;
      }
      toast.error("Oops somthing went wrong !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      console.log(error, "err");
    }
    setIsLoading(false);
  };

  const handleMetaFieldInputChange = (e) => {
    const { name, value } = e.target;
    setMetaData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitMetaField = (e) => {
    e.preventDefault();
    setUpdateData((prev) => ({ ...prev, metaData: metaData }));
  };

  const handleSelectDefectChange = (e) => {
    const selectedValue = e.target.value;
    const newData = data.map((item) => {
      if (item.defectPage === selectedDefectsPage) {
        return {
          ...item,
          defectFounds: [...item.defectFounds, selectedValue],
        };
      }
      return item;
    });
    setData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleRemarksChange = (e) => {
    const selectedValue = e.target.value;
    const newData = data.map((item) => {
      if (item.defectPage === selectedDefectsPage) {
        return {
          ...item,
          remarks: selectedValue,
        };
      }
      return item;
    });
    setData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleRemoveClick = (defectedItem, defectedPage) => {
    const newData = [...data];

    newData.forEach((item) => {
      if (item.defectPage === defectedPage) {
        const newDefectsFound = item.defectFounds.filter(
          (defect) => defect !== defectedItem
        );
        item.defectFounds = newDefectsFound;
      }
    });
    setData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleUpdate = async () => {
    const { id } = updateData || {};
    try {
      const response = await fetch(`${REACT_APP_API_KEY}/updateDefects/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // credentials: "include",
        body: JSON.stringify(updateData),
      });
      await response.json();
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }
  };

  const uploadNewDocument = () => {
    setShowPopup(true);
    setData([]);
    setUpdateData({});
    setFile(null);
  };

  const existingTab = () => {
    setData([]);
    setUpdateData({});
  };

  return {
    showPopup,
    file,
    isLoading,
    handleSelectDefectChange,
    handleRemoveClick,
    handleFileUpload,
    data,
    setSelectedDefectsPage,
    selectedDefectsPage,
    uploadNewDocument,
    handleUpdate,
    handleRemarksChange,
    handleSubmitMetaField,
    handleMetaFieldInputChange,
    updateData,
    existingTab,
    defectsCategory,
    setDefectsCategory,
    selectedCourt,
    setSelectedCourt,
  };
};

export default useDefectAnalysisData;
