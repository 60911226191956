import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useUpdateEfiling = ({ updatedData }) => {
  const userState = useContext(UserContext);
  const {
    setShowNavigation = () => { },
    setIsLoggedIn = () => { },
  } = userState || {};
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleEfilingUpdate = async (id) => {
    try {
      const response = await fetch(`${REACT_APP_API_KEY}/updateEfiling/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        // credentials: "include",
        body: JSON.stringify(updatedData),
      });
      await response.json();

      if (response.ok) {
        toast.success("Data is successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error("Oops somthing went wrong!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
        return;
      }
      toast.error("Oops somthing went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
  return {
    handleEfilingUpdate,
  };
};

export default useUpdateEfiling;
