import React from "react";

export function Arrow({ headerColumn, activeColumn, order, onClick }) {
  return (
    <svg
      className={`w-4 h-4 cursor-pointer ${
        activeColumn === headerColumn
          ? "text-black"
          : "text-[#BCBDBE] group-hover:text-black rotate-180"
      } ${
        activeColumn === headerColumn
          ? order === "desc"
            ? "rotate-0"
            : "rotate-180"
          : ""
      }
    `}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 14l-7 7m0 0l-7-7m7 7V3"
      />
    </svg>
  );
}
