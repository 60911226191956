import React from "react";

const CodeCategories = ({ isEditable, updatedData = {} }) => {
  const { relevant_case_types = [] } = updatedData || {};
  return (
    <div className="flex justify-center pb-4">
      <div className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5">
        <div className="flex">
          <div className="text-[#192839] text-base font-semibold">
            Code & Descriptions
          </div>
        </div>
        <hr className="my-4"></hr>
        <div>
          <div className="my-2 border text-sm rounded-lg">
            <div className="flex text-sm bg-gray-200 rounded-md rounded-b-none ">
              <div className="w-[10%] py-2 border-r items-center border-gray-300 flex justify-center">
                S.NO.
              </div>
              <div className="w-[10%] py-2 border-r items-center border-gray-300 flex justify-center">
                Code
              </div>
              <div className="w-[80%] flex items-center justify-center">
                Description
              </div>
            </div>
            {relevant_case_types === undefined
              ? null
              : (relevant_case_types || []).map((rectify, index) => {
                  const { code, description } = rectify || {};
                  return (
                    <div
                      className="flex  last:border-none border-b"
                      key={index}
                    >
                      <div
                        className={`w-[10%] flex justify-center border-r py-2 flex-wrap  items-center ${
                          isEditable ? "bg-white" : " bg-[#6C849D52]"
                        } text-[#40566D]`}
                      >
                        {index + 1}
                      </div>
                      <div
                        className={`flex justify-center border-r py-2 flex-wrap  items-center  w-[10%] ${
                          isEditable ? "bg-white" : "bg-[#6C849D52]"
                        }   text-[#40566D]`}
                      >
                        {code}
                      </div>
                      <div
                        className={`w-[80%] py-2 flex px-4 items-center ${
                          isEditable ? "bg-white" : "bg-[#6C849D52]"
                        }  text-[#40566D]`}
                      >
                        {description}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeCategories;
