import React, { useState, useRef, useCallback } from "react";
import RecordRTC from "recordrtc";
import { StereoAudioRecorder } from "recordrtc";
const { REACT_APP_WS_URL } = process.env;

const GetRealSTT = ({ language, setLanguage, transcript, setTranscript, fileId, setFileId, filename, setFilename }) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [curTime, setCurTime] = useState(0);
  const wsRef = useRef(null);
  const recorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const [fileDisable, setFileDisable] = useState(false);
  const transcriptDictRef = useRef({});


  const blobToBase64 = useCallback((blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }, []);

  const handleDataAvailable = useCallback((event) => {
    if (event.size > 0) {
      setCurTime(curTime+3);
      blobToBase64(event).then((b64) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          const message = {
            time: curTime,
            audio: b64,
            lang: language
          };
          wsRef.current.send(JSON.stringify(message));
          // console.log(curTime);
        }
      });
    }
  }, [blobToBase64, curTime, language]);
  
  const updateTranscript = useCallback((text, time) => {
    // const { time, text } = update;

    // Store the transcript with the timestamp in the global dictionary
    transcriptDictRef.current[time] = text;
    console.log(text);
    
    for (let i = 1; i < 10; i++) {
      delete transcriptDictRef.current[time+(i*3)];
    }

    console.log(Object.keys(transcriptDictRef).length);
    // Update the transcript by concatenating all parts sorted by timestamp
    const sortedTimestamps = Object.keys(transcriptDictRef.current).sort((a, b) => a - b);
    const fullTranscript = sortedTimestamps.map(timestamp => transcriptDictRef.current[timestamp]).join(" ");

    setTranscript(fullTranscript);
  }, [setTranscript]);

  const startStreaming = async () => {
    
    setTranscript("");
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream; // Save the stream reference
        
        // Initialize the recorder
        const recorder = RecordRTC(stream, {
          type: "audio",
          recorderType: StereoAudioRecorder,
          mimeType: "audio/wav",
          timeSlice: 3000,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          ondataavailable: handleDataAvailable,
        });
        
        recorderRef.current = recorder;
        
        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
          const ws = new WebSocket(`${REACT_APP_WS_URL}/transcript`);
          wsRef.current = ws;
          
          ws.onmessage = (event) => {
            var data=JSON.parse(event.data);
            if(data.id){
              setFileId(data.id);
            }
            if('transcript' in data){
              if('update' in data){
                updateTranscript(data.transcript, data.time);
              }else{
                transcriptDictRef.current[data.time] = data.transcript;
                setTranscript((prev) => `${prev} ${data.transcript}`);
              }
            }
          };
          
          ws.onopen = () => {
            setFileDisable(true);
            // Ensure recorder is properly started
            setTimeout(() => {
              if (recorderRef.current) {
                recorderRef.current.startRecording();
                setIsStreaming(true);
              }
            }, 100); // Short delay to ensure proper initialization
          };

          ws.onclose = () => {
            stopStreaming(); // Stop recording and release microphone when WebSocket closes
          };
        }
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });
    }
  };

  const stopStreaming = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording();
    }
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
    setIsStreaming(false);
    setFileDisable(false);
  };

  const onRestart = () => {
    setFilename("");
    setTranscript("");
    stopStreaming();
    setCurTime(0);
    setFileDisable(false);
    setFileId("");
  }

  const handleFilenameChange = (event) => {
    setFilename(event.target.value);
  };

  return (
    <div className="sm:px-6 w-full">
      <div className="flex justify-between items-center mb-4">
        <div className="flex">
          <input
            type="text"
            id="filename"
            name="filename"
            className="border rounded-l p-2 m-0 h-10"
            placeholder="Filename"
            value={filename}
            onChange={handleFilenameChange}
            disabled={fileDisable}
          />
          <span className="border-t border-b border-r text-gray-500 bg-gray-100 rounded-r p-2 m-0 h-10">
            .mp3
          </span>
        </div>
        <div className="flex">
          <button
            onClick={isStreaming ? stopStreaming : startStreaming}
            className={`px-4 py-2 rounded ${isStreaming ? "bg-red-500 hover:bg-red-700" : "bg-green-500 hover:bg-green-700"} text-white mr-2 h-10`}
            disabled={!filename}
          >
            {isStreaming ? "Stop Recording" : "Start Recording"}
          </button>
          <button
            onClick={ onRestart }
            className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-700 text-white h-10"
          >
            Restart
          </button>
        </div>
      </div>
      <div className="my-4">
        <label htmlFor="language" className="block text-sm font-medium text-gray-700">
          Select Language:
        </label>
        <select
          id="language"
          name="language"
          className="mt-1 block w-full p-2 border rounded"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          disabled={fileDisable}
        >
          <option value="en">English</option>
          <option value="as">Assamese</option>
          <option value="bn">Bengali</option>
          <option value="gu">Gujarati</option>
          <option value="hi">Hindi</option>
          <option value="kn">Kannada</option>
          <option value="ml">Malayalam</option>
          <option value="mr">Marathi</option>
          <option value="or">Odia</option>
          <option value="pa">Punjabi</option>
          <option value="ta">Tamil</option>
          <option value="te">Telugu</option>
          <option value="ur">Urdu</option>
        </select>
      </div>
      <hr className="sm:-mx-6 mb-4" />
      <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
        <textarea
          className="w-full h-40 p-2 border rounded"
          value={transcript}
          onChange={(e) => setTranscript(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};

export default GetRealSTT;
