import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import JSONPretty from "react-json-pretty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY | HH:mm");
};
const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

const AddedDefectHistory = () => {
  const [data, setData] = useState([]);
  const [docDetails, setDocDetails] = useState([]);
  const location = useLocation();
  const docId = location.state;

  const extractDateAndTime = (timestampString) => {
    const dateObject = new Date(timestampString);

    const date = dateObject.toISOString().split("T")[0];
    const time = dateObject.toTimeString().split(" ")[0];

    return date + " " + time;
  };

  const numberOfDefectsChanged = (data) => {
    let totalCategoryLength = 0;

    data.defects.forEach((defect) => {
      defect.defectsFound.forEach((foundDefect) => {
        totalCategoryLength += foundDefect.category.length;
      });
    });

    return totalCategoryLength;
  };

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleClick = (e, index) => {
    e.currentTarget.closest(".item").classList.toggle("active");

    const fetchDocDetails = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_KEY}/all-audit-history/${docId}`,
          { 
            // withCredentials: true,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        const data = response.data;

        const newDocDetails = [...docDetails];
        newDocDetails[index] = data.auditHistory[index];
        setDocDetails(newDocDetails);
      } catch (error) {
        console.log("Error Fetching Doc Details", error);
        if(error.response.status === 401){
          toast.error("Invalid Token. Please Login!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      }
    };

    if (e.currentTarget.closest(".item").classList.contains("active")) {
      if (typeof docDetails[index] !== "object") {
        fetchDocDetails();
      }
    }
  };

  useEffect(() => {
    // const jwtToken = Cookies.get("token");
    // console.log("TOKEN IS ", jwtToken);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_KEY}/audit-history/${docId}`,
          { 
            // withCredentials: true,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        const data = response.data;
        setData(data.auditHistory);
        setDocDetails(new Array(data.length).fill(""));
        // setDocDetails(data.auditHistory);
      } catch (error) {
        console.log("Error Fetching Data", error);
        if(error.response.status === 401){
          toast.error("Invalid Token. Please Login!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      }
    };

    fetchData();
  }, [docId, token]);

  const handleBack = async (id) => {
    const encodedData = { id: id };
    const navigateURL = `${REACT_APP_BASE_URL}/SelectedFileAnalysis?data=${JSON.stringify(
      encodedData
    )}`;
    window.open(navigateURL, "_self");
  };

  return (
    <div className="py-2 mx-6">
      <ToastContainer />
      <div className="flex justify-between">
        <button onClick={() => handleBack(docId)}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-lg font-semibold">Audit History</h1>
      </div>
      <hr className="sm:-mx-6 mt-2"></hr>

      <div className="max-h-screen relative flex flex-col my-5">
        {data?.length > 0 ? (
          <div className="flex-grow overflow-x-auto border">
            <div className="w-full p-2 flex bg-[#6C849D1F] text-[#192839] font-medium text-sm">
              <div className="w-[30%] text-[#192839] font-semibold">User</div>
              <div className="w-[30%] text-[#192839] font-semibold">
                Updated At
              </div>
              <div className="w-[30%] text-[#192839] font-semibold">
                Defects
              </div>
            </div>

            <div id="accordion" className="overflow-x-auto">
              {data?.map((data, idx) => {
                return (
                  <div className="item" key={idx}>
                    <div
                      className="header text-[#192839] p-1.5 w-full cursor-pointer"
                      onClick={(e) => handleClick(e, idx)}
                    >
                      <div className="flex w-full font-normal text-sm">
                        <div className="w-[30%]">{data.username}</div>
                        <div className="w-[30%]">
                          {formatDateTime(extractDateAndTime(data.timestamp))}
                        </div>
                        <div className="w-[30%]">
                          {numberOfDefectsChanged(data)}
                        </div>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#4D64DC"
                          class="w-6 h-6 activeIcon"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#4D64DC"
                          class="w-6 h-6 inactiveIcon"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="content transition-all bg-[#FFF]">
                      {docDetails[idx] ? (
                        <JSONPretty
                          id="json-pretty"
                          style={{ fontSize: "12px" }}
                          data={docDetails[idx]}
                          mainStyle="padding:10px"
                          valueStyle="font-size:14px"
                        ></JSONPretty>
                      ) : (
                        <div className="flex justify-center items-center">
                          Loading Details...
                        </div>
                      )}
                    </div>
                    <hr></hr>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="text-center">No History Found</div>
        )}
      </div>
    </div>
  );
};

export default AddedDefectHistory;
