import React, { useState } from "react";
import useGetSimilaritySearch from "./useGetSimilaritySearch";
import { ToastContainer } from "react-toastify";
import ExistingSimilaritySearchDoc from "./ExistingSimilaritySearchDoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileUploadModal from "../common/FileUploadModal";
import useGetExistingSimSearch from "./useGetExistingSimSearch";
function SimilaritySearch() {
  const {
    existingSimSearchData = [],
    existingDataLoading,
    setEistingDataLoading,
    filters,
    setFilters,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    sortingColumn,
    order,
    handleFileNameClick,
    getExistingData,
  } = useGetExistingSimSearch();
  const { showFileUpload, handleSimilaritySearchFileUpload, isUplaodLoading } =
    useGetSimilaritySearch({ getExistingData });

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  return (
    <div className="sm:px-4 w-full">
      <ToastContainer />
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">
          Similar Case Bunching
        </div>
        <div>
          <button
            onClick={() => setShowFileUploadModal(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Upload Document</span>
          </button>
        </div>
      </div>
      <hr className="sm:-mx-4"></hr>

      {showFileUpload ? (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          setShowFileUploadModal={setShowFileUploadModal}
          handleFileUpload={handleSimilaritySearchFileUpload}
          isLoading={isUplaodLoading}
          heading="Similar Case Bunching"
          subHeading="Upload the file to find the similar cases"
        />
      ) : null}
      <ExistingSimilaritySearchDoc
        existingSimSearchData={existingSimSearchData}
        existingDataLoading={existingDataLoading}
        setEistingDataLoading={setEistingDataLoading}
        filters={filters}
        setFilters={setFilters}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setOrder={setOrder}
        setSortingColumn={setSortingColumn}
        sortingColumn={sortingColumn}
        order={order}
        handleFileNameClick={handleFileNameClick}
      />
    </div>
  );
}

export default SimilaritySearch;
