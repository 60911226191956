import React, { useState } from "react";
import Modal from "../../common-components/Modal";
import UploadDocument from "../common/uploadDocument";

const FileUploadModal = ({
  showFileUploadModal = false,
  setShowFileUploadModal = () => {},
  handleFileUpload = () => {},
  defectsCategory = [],
  setDefectsCategory = () => {},
  currentTab = "",
  isLoading = false,
  heading = "",
  subHeading = "",
  selectedCourt = "",
  setSelectedCourt = () => {},
}) => {
  const [fileList, setFileList] = useState([]);

  const handleSave = (e) => {
    e.preventDefault();
    handleFileUpload(fileList);
    setFileList([]);
    setTimeout(() => {
      setShowFileUploadModal(false);
    }, 1000);
  };
  let disabledConditions = isLoading || fileList.length === 0;
  if (currentTab === "DefectAnalysis") {
    disabledConditions = disabledConditions || selectedCourt === "";
  }

  return (
    <div>
      <Modal
        showModal={showFileUploadModal}
        closeModal={() => {
          setShowFileUploadModal(false);
          setSelectedCourt("");
        }}
        heading={heading}
        subHeading={subHeading}
        modalHeight="min-h-max"
        // heading="Legal Document Digitalization"
        // subHeading="Upload a legal document to digitize"
      >
        <hr className="-mx-5 my-3" />
        <UploadDocument
          setFileList={setFileList}
          fileList={fileList}
          currentTab={currentTab}
          setSelectedCourt={setSelectedCourt}
          selectedCourt={selectedCourt}
          defectsCategory={defectsCategory}
          setDefectsCategory={setDefectsCategory}
        />
        <hr className="-mx-5 my-3" />
        <div className="flex justify-end">
          <button
            onClick={() => {
              setShowFileUploadModal(false);
              setSelectedCourt("");
            }}
            className="rounded px-2 py-1 me-4 text-sm border border-[#5E48FCCC]  text-[#5E48FCCC]"
          >
            Cancel
          </button>
          <button
            // disabled={isLoading || fileList.length === 0}
            disabled={disabledConditions}
            onClick={(e) => {
              handleSave(e);
              // handleFileUpload(fileList);
              // setFileList([]);
              // setTimeout(() => {
              //   setShowFileUploadModal(false);
              // }, 800);
            }}
            className="bg-[#5E48FC] hover:bg-[#5E48FCCC] text-sm px-4 disabled:text-gray-500 py-1 rounded text-[#FFFFFF] disabled:bg-[#6C849D17]"
          >
            {isLoading ? "Uploading..." : "Upload"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default FileUploadModal;
