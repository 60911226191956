import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import DefectCategoryUpload from "./DefectCategoryUpload";

// const OPTIONS = [
//   { label: "Missing Signature", value: "Missing Signature" },
//   { label: "Missing Page Number", value: "Missing Page Number" },
//   { label: "Blurred Page", value: "Blurred Page" },
//   { label: "Missing Prayer", value: "Missing Prayer" },
//   { label: "Vernacular Language", value: "Vernacular Language" },
//   { label: "Bookmarking", value: "Bookmarking" },
//   { label: "Vakalatanama", value: "Vakalatanama" },
// ];

const UploadDocument = ({
  // handleFileUpload = () => {},
  defectsCategory = [],
  setDefectsCategory = () => {},
  currentTab = "",
  fileList = [],
  setFileList = () => {},
  selectedCourt = "",
  setSelectedCourt = () => {},
}) => {
  const [dragOver, setDragOver] = useState(false);

  // const filteredDefectOptions = OPTIONS.filter(
  //   (item) => !defectsCategory?.some((defect) => defect === item.label)
  // );

  // const handleChanges = (e) => {
  //   setDefectsCategory([...defectsCategory, e.target.value]);
  // };

  // const handleRemoveDefects = (deletedDefect) => {
  //   const newDefect = defectsCategory.filter(
  //     (defect) => defect !== deletedDefect
  //   );
  //   setDefectsCategory(newDefect);
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFileList(e.dataTransfer.files);
    // handleFileUpload(e.dataTransfer.files);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="w-full">
      <div>
        <div className="extraOutline m-auto rounded-lg">
          {currentTab === "DefectAnalysis" ? (
            <DefectCategoryUpload
              defectsCategory={defectsCategory}
              setDefectsCategory={setDefectsCategory}
              setSelectedCourt={setSelectedCourt}
              selectedCourt={selectedCourt}
            />
          ) : null}

          {Array.from(fileList).map((item, index) => {
            return (
              <div key={index} className="text-[#192839] font-normal text-sm">
                {item.name}
              </div>
            );
          })}

          <div
            className={`w-full items-center my-5 mx-auto p-5 relative border-[2px] border-dashed border-[#727272ab] rounded-lg flex justify-center hover:cursor-pointer ${
              dragOver ? "bg-[#cecccc78]" : ""
            }`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handleClick}
          >
            <input
              ref={inputRef}
              className="hidden"
              type="file"
              multiple
              onChange={(e) => {
                // handleFileUpload(e.target.files);
                setFileList(e.target.files);
              }}
            />
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCloudArrowUp} height={40} width={40} />
              <div className="title text-[#333333CC] font-medium">
                <span>Click to upload</span>
                <span className="uppercase mx-2">Or</span>
                <span>Drag your files here</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
