import React, { useState } from "react";
import Modal from "../../common-components/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilteredSegment from "./FilteredSegment";

const UpdateSpeakerModal = ({
  showUpdateModal,
  setShowUpdateModal,
  setFetchedTranscript,
  fetchedTranscript,
  spkFilteredList,
  setSpkFilteredList,
}) => {
  const [list, setList] = useState(() =>
    spkFilteredList.map((block) => ({ ...block, ticked: true }))
  );

  function toggleThisSegment(start) {
    const updatedList = list.map((block) =>
      block.start === start ? { ...block, ticked: !block.ticked } : block
    );
    setList(updatedList);
  }

  function handleUpdateModal() {
    const finalList = list.filter((block) => block.ticked);
    setSpkFilteredList(finalList);

    const updatedTranscript = fetchedTranscript.map((transcriptBlock) => {
      const correspondingBlock = finalList.find(
        (block) => block.start === transcriptBlock.start
      );
      if (correspondingBlock) {
        return { ...transcriptBlock, speaker: correspondingBlock.speaker };
      }
      return transcriptBlock;
    });

    setFetchedTranscript(updatedTranscript);
    setShowUpdateModal(false);
    toast.info("To Save it permanently please Click on the Save button", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  }

  return (
    <Modal
      closeModal={() => setShowUpdateModal(false)}
      showModal={showUpdateModal}
      modalWidth="w-3/5"
      modalHeight="h-3/5"
    >
      <div className="flex justify-center font-medium text-sm text-[#192839]">
        <h1>All the speakers in the segments below will be replaced:-</h1>
      </div>
      <div className="p-4">
        {list.map((block) => (
          <FilteredSegment
            key={block.start}
            start={block.start}
            text={block.text}
            ticked={block.ticked}
            toggleThisSegment={toggleThisSegment}
          />
        ))}
      </div>
      <div className="flex justify-center my-5">
        <button
          onClick={() => setShowUpdateModal(false)}
          className="text-[#2950DA] px-4 py-1.5 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
        >
          Cancel
        </button>
        <button
          onClick={() => handleUpdateModal()}
          className="bg-[#5E48FC] px-6 py-1.5 ml-4 text-xs rounded shadow-md text-white"
        >
          Update
        </button>
      </div>
    </Modal>
  );
};

export default UpdateSpeakerModal;
