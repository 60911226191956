import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Select from "../../common-components/Select";
import { COURT_LIST } from "../../constants/CourtList";
import { SUPREME_COURT_DEFECT_LIST } from "../../constants/supremeCourtDefectList";
import { DELHI_HIGH_COURT_DEFECT_LIST } from "../../constants/delhiHighCourtDefectList";

const DefectCategoryUpload = ({
  defectsCategory = [],
  setDefectsCategory = () => {},
  selectedCourt = "",
  setSelectedCourt = () => {},
}) => {
  const COURT_WISE_DEFECT_MAPPINNG = {
    "Supreme Court of India": SUPREME_COURT_DEFECT_LIST,
    "Delhi High Court": DELHI_HIGH_COURT_DEFECT_LIST,
  };

  const filteredDefectOptions = COURT_WISE_DEFECT_MAPPINNG[
    selectedCourt
  ]?.filter(
    (item) => !defectsCategory?.some((defect) => defect === item.label)
  );

  const handleChanges = (e) => {
    setDefectsCategory([...defectsCategory, e.target.value]);
  };

  const handleRemoveDefects = (deletedDefect) => {
    const newDefect = defectsCategory.filter(
      (defect) => defect !== deletedDefect
    );
    setDefectsCategory(newDefect);
  };
  const onSelect = (option) => {
    setSelectedCourt(option.value);
  };

  useEffect(() => {
    setDefectsCategory([]);
    // eslint-disable-next-line
  }, [selectedCourt]);

  return (
    <div>
      <div className="flex justify-between my-2">
        <div className="w-1/3">
          <Select
            options={COURT_LIST}
            onSelect={onSelect}
            placeholder="Select A Court Name"
            readOnly
          />
        </div>
        <select
          onChange={(e) => handleChanges(e)}
          value=""
          disabled={selectedCourt === ""}
          className={`border placeholder:text-[#D1D5DB] ${
            selectedCourt === "" ? "border-[#D1D5DB]" : "border-gray-700"
          }  rounded focus:outline-none bg-white`}
        >
          <option value="" disabled>
            Select Defect Categories
          </option>
          {filteredDefectOptions?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {defectsCategory.length > 0 && (
        <div className="text-[#192839]">Selected Defect Categories :</div>
      )}
      <div className="w-full flex flex-wrap mt-1 mb-2">
        {defectsCategory
          ? defectsCategory.map((defect, idx) => {
              return (
                <div
                  key={idx}
                  className="bg-[#D92D20] font-normal text-sm px-3 py-1 rounded-3xl m-1 text-white"
                >
                  {defect}
                  <button
                    className="ml-2"
                    onClick={() => handleRemoveDefects(defect)}
                  >
                    <FontAwesomeIcon icon={faXmark} color="indigo" />
                  </button>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default DefectCategoryUpload;
