import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useGetSelectedSimilarity = ({ id }) => {
  const [similarityDoc, setSimilarityDoc] = useState(null);

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getSelectedSimilarityDoc = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getSimilaritySearchDataById`,
        {
          params: {
            id: id,
          },
          // withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSimilarityDoc(response.data._source);
    } catch (err) {
      console.error(err || "Oops somthing went wrong");
      if(err.response.status === 401){
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        return;
      }
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  }, [id, token]);

  useEffect(() => {
    getSelectedSimilarityDoc();
  }, [getSelectedSimilarityDoc]);

  return {
    similarityDoc,
  };
};

export default useGetSelectedSimilarity;
