import React from "react";
import { ToastContainer } from "react-toastify";
import InputLayout from "./InputLayout";

function EarlierCourt({
  // data = {},
  isEditable,

  earlierCourtDetails = {},
  setEarlierCourtDetails = () => {},
}) {
  return (
    <div>
      <div className="flex relative justify-center">
        <form
          className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
          // onSubmit={handleSaveDraft}
        >
          <div>
            <div className=" text-[#192839]  text-base font-semibold">
              Earlier Courts
            </div>
          </div>
          <hr className="my-4"></hr>

          <InputLayout
            isEditable={isEditable}
            petitionerData={earlierCourtDetails}
            setPetitionerData={setEarlierCourtDetails}
          />
        </form>

        <ToastContainer />
      </div>
    </div>
  );
}

export default EarlierCourt;
