import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const Pagination = ({
  totalCount,
  totalPages,
  setCurrentPage,
  currentPage,
  setIsLoading,
  pageSize = 10,
}) => {
  const startItem = (currentPage - 1) * 10 + 1;
  let endItem = startItem - 1 + pageSize;
  if (endItem > totalCount) {
    endItem = totalCount;
  }

  useEffect(() => {
    localStorage.setItem("currentDocumentPage", currentPage);
  }, [currentPage]);

  return (
    <div className="bg-white flex text-sm items-center p-1.5 w-full ">
      <strong className="mr-2 text-[#192839] font-normal flex-shrink-0">
        Showing Results {startItem} - {endItem} out of {totalCount}
      </strong>

      <div className="flex-shrink-0">
        <button
          onClick={() => {
            setCurrentPage(
              currentPage === 1 ? totalPages : Number(currentPage) - 1
            );
            setIsLoading(true);
          }}
          className="bg-white shadow rounded px-2.5 py-0.5 disabled:bg-gray-300"
          disabled={totalPages === 1}
        >
          <FontAwesomeIcon icon={faCaretLeft} color="#192839" />
        </button>
        <strong className="mx-2 text-sm font-normal text-[#192839]">
          {currentPage}{" "}
        </strong>
        <button
          onClick={() => {
            setCurrentPage(
              currentPage === totalPages ? 1 : Number(currentPage) + 1
            );
            setIsLoading(true);
          }}
          className="bg-white shadow rounded px-2.5 py-0.5 disabled:bg-gray-300"
          disabled={totalPages === 1}
        >
          <FontAwesomeIcon icon={faCaretRight} color="#192839" />
        </button>
      </div>
    </div>
  );
};
export default Pagination;
