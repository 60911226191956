import React, { useState } from "react";
import useUploadAudio from "./useUploadAudio";
import { ToastContainer } from "react-toastify";
import AudioList from "./AudioList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AudioRecordModel from "./AudioRecordModel";
import useGetAudioList from "./useGetAudioList";

const RealTimeSTT = () => {
  const {
    audioList,
    isAudioListLoading,
    setIsAudioListLoading,
    filters,
    setFilters,
    handleFileNameClick,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    order,
    sortingColumn,
    getAudioList,
  } = useGetAudioList();

  const { handleAudioFileUpload, isUplaodLoading } = useUploadAudio({
    getAudioList,
  });
  const [showAudioRecordModel, setShowAudioRecordModel] = useState(false);
  return (
    <div className="sm:px-4 w-full">
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">
          Live Transcription
        </div>
        <div>
          <button
            onClick={() => setShowAudioRecordModel(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Record Audio</span>
          </button>
        </div>
      </div>
      <hr className="sm:-mx-4"></hr>
      <ToastContainer />
      {showAudioRecordModel ? (
        <AudioRecordModel
          showAudioRecordModel={showAudioRecordModel}
          setShowAudioRecordModel={setShowAudioRecordModel}
          handleFileUpload={handleAudioFileUpload}
          isLoading={isUplaodLoading}
          heading="Live Transcription"
          subHeading="Record the Audio for Live Transcription"
        />
      ) : null}
      <AudioList
        audioList={audioList}
        isAudioListLoading={isAudioListLoading}
        setIsAudioListLoading={setIsAudioListLoading}
        filters={filters}
        setFilters={setFilters}
        handleFileNameClick={handleFileNameClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setOrder={setOrder}
        setSortingColumn={setSortingColumn}
        order={order}
        sortingColumn={sortingColumn}
      />
    </div>
  );
};

export default RealTimeSTT;
