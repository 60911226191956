import React from "react";
import { ToastContainer } from "react-toastify";

const ForgotPage = ({
  resetPassword = () => {},
  forgotFormData = {},
  handleForgotInputChange,
  forgotData,
  isResetPaaswordLoading,
}) => {
  const { email, newPassword, confirmNewPassword, otp } = forgotFormData || {};

  return (
    <div className="pt-2">
      <h2 className="text-red-500 mb-2 ms-6">{forgotData?.message}</h2>
      <hr></hr>
      <form onSubmit={resetPassword} className="px-6 pt-2">
        <div className="mb-4">
          <label className="text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleForgotInputChange}
            placeholder="Enter your Email"
            required
            disabled
            className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label className="text-gray-700">OTP</label>
          <input
            type="text"
            name="otp"
            value={otp}
            onChange={handleForgotInputChange}
            placeholder="Enter your OTP"
            required
            className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label className="text-gray-700">New Password</label>
          <input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={handleForgotInputChange}
            placeholder="Enter your new password"
            required
            className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="text-gray-700">Confirm New Password</label>
          <input
            type="password"
            name="confirmNewPassword"
            value={confirmNewPassword}
            onChange={handleForgotInputChange}
            placeholder="Enter your new password"
            required
            className="w-full p-1.5 border rounded mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="-mx-6 mb-6">
          <hr></hr>
        </div>
        <button
          type="submit"
          disabled={isResetPaaswordLoading}
          className="disabled:bg-gray-500 bg-[#5E48FC] hover:bg-indigo-700 text-white w-full rounded p-2"
        >
          {isResetPaaswordLoading ? "Loading" : "Verify"}
        </button>
        <ToastContainer />
      </form>
    </div>
  );
};

export default ForgotPage;
