import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useGetSelectedRectifiedData = ({ rectifyId }) => {
  const [selectedRectifiedData, setSelectedRectifiedData] = useState({});

  const getSelectedRectifiedData = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      const res = await axios.get(
        `${REACT_APP_API_KEY}/getRectifiedPageDataById?id=${rectifyId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedRectifiedData(res.data._source);
      if (res.data._source.status === "Completed") {
        return true; // Indicate that the status is completed
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    return false;
  }, [rectifyId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isCompleted = await getSelectedRectifiedData();
      if (isCompleted) {
        clearInterval(intervalId); // Stop calling the function if status is "Completed"
      }
    }, 5000); // Call every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [getSelectedRectifiedData]);

  useEffect(() => {
    getSelectedRectifiedData();
  }, [rectifyId, getSelectedRectifiedData]);

  return {
    selectedRectifiedData,
  };
};

export default useGetSelectedRectifiedData;
