import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Dskeleton({ columns }) {
  const skeletonColumns = Array.from({ length: columns }).map(
    (_, columnIndex) => (
      <td key={columnIndex} className="py-2 px-4">
        <Skeleton />
      </td>
    )
  );

  const skeletonRows = Array.from({ length: 10 }).map((_, rowIndex) => (
    <tr
      key={rowIndex}
      className="hover:bg-gray-200 bg-white border-b-2 border-grey-100"
    >
      {skeletonColumns}
    </tr>
  ));

  return skeletonRows;
}

export default Dskeleton;
