/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
import useDebounceQuery from "../common/useDebounceQuery";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

const useGetVideoList = () => {
  const [videoList, setVideoList] = useState([]);
  const [isVideoListLoading, setIsVideoListLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sortingColumn, setSortingColumn] = useState("uploadedAt");
  const [filters, setFilters] = useState({
    searchInput: undefined,
    dates: undefined,
  });

  const userState = useContext(UserContext);
  const { setShowNavigation = () => {}, setIsLoggedIn = () => {} } =
    userState || {};
  const navigate = useNavigate();

  const { searchInput, dates } = filters;
  const [startDate, endDate] = dates || [];
  const { query = "", debounceQuery } = useDebounceQuery();

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    debounceQuery(searchInput);
  }, [debounceQuery, searchInput]);

  const getVideoList = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getExistingVideoList`,
        {
          params: {
            q: query || undefined,
            startDate: startDate,
            endDate: endDate,
            pageNumber: currentPage,
            pageSize: 10,
            sortingColumn: sortingColumn,
            order: order,
          },
          // withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVideoList(response?.data);
      setIsVideoListLoading(false);
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
        return;
      }
      toast.error("Oops Somthing went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    getVideoList();
  }, [query, startDate, endDate, currentPage, order, sortingColumn]);

  const handleFileNameClick = async (id) => {
    const encodedData = { id: id };
    const navigateURL = `${REACT_APP_BASE_URL}/SelectedVideo?data=${JSON.stringify(
      encodedData
    )}`;
    window.open(navigateURL, "_self");
  };

  return {
    videoList,
    isVideoListLoading,
    filters,
    setFilters,
    setIsVideoListLoading,
    handleFileNameClick,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    order,
    sortingColumn,
    getVideoList,
  };
};

export default useGetVideoList;

// import axios from "axios";
// import { useCallback, useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import useDebounceQuery from "../common/useDebounceQuery";
// const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

// const useGetVideoList = () => {
//   const [videoList, setVideList] = useState({});
//   const [isVideoListLoading, setIsVideoListLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [order, setOrder] = useState("desc");
//   const [sortingColumn, setSortingColumn] = useState("uploadedAt");
//   const [filters, setFilters] = useState({
//     searchInput: undefined,
//     dates: undefined,
//   });
//   const { searchInput, dates } = filters;
//   const [startDate, endDate] = dates || [];
//   const { query = "", debounceQuery } = useDebounceQuery();

//   const token = JSON.parse(localStorage.getItem("userDetails")).token;

//   useEffect(() => {
//     debounceQuery(searchInput);
//   }, [debounceQuery, searchInput]);

//   const getVideoList = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${REACT_APP_API_KEY}/getExistingVideoList`,
//         {
//           params: {
//             q: query || undefined,
//             startDate: startDate,
//             endDate: endDate,
//             pageNumber: currentPage,
//             pageSize: 10,
//             sortingColumn: sortingColumn,
//             order: order,
//           },
//           // withCredentials: true,
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${token}`,
//           },
//         }
//       );
//       setVideList(response?.data);
//       setIsVideoListLoading(false);
//     } catch (error) {
//       console.log(error, "error");
//       if(error.response.status === 401){
//         toast.error("Invalid Token. Please Login!", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 2000,
//         });
//         return;
//       }
//       toast.error("Oops Somthing went wrong", {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 2000,
//       });
//     }
//   }, [query, startDate, endDate, currentPage, order, sortingColumn, token]);

//   const handleFileNameClick = async (id) => {
//     const encodedData = { id: id };
//     const navigateURL = `${REACT_APP_BASE_URL}/SelectedVideo?data=${JSON.stringify(
//       encodedData
//     )}`;
//     window.open(navigateURL, "_self");
//   };

//   useEffect(() => {
//     getVideoList();
//   }, [
//     query,
//     startDate,
//     getVideoList,
//     endDate,
//     currentPage,
//     order,
//     sortingColumn,
//   ]);

//   return {
//     videoList,
//     isVideoListLoading,
//     filters,
//     setFilters,
//     setIsVideoListLoading,
//     handleFileNameClick,
//     currentPage,
//     setCurrentPage,
//     setOrder,
//     setSortingColumn,
//     order,
//     sortingColumn,
//     getVideoList,
//   };
// };

// export default useGetVideoList;
