import InputLayout from "./InputLayout";

function Respondent({
  isEditable,
  respondentDetails = {},
  setRespondentDetails = () => {},
}) {
  return (
    <div className="flex justify-center pb-4">
      <form className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5">
        <div className="flex justify-between items-center">
          <div className="text-[#192839]  text-base font-semibold">
            Respondent Information
          </div>
        </div>
        <hr className="my-4"></hr>

        <InputLayout
          isEditable={isEditable}
          petitionerData={respondentDetails}
          setPetitionerData={setRespondentDetails}
        />
      </form>
    </div>
  );
}

export default Respondent;
