import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../Routing";
const { REACT_APP_API_KEY } = process.env;

const useForgotPassword = ({ formData = {} }) => {
  const userState = useContext(UserContext);
  const {
    setIsLoggedIn = () => {},
    setUserDetails = () => {},
    setActiveNavbar = () => {},
  } = userState || {};
  const [forgotData, setForgotData] = useState({});
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState(false);
  const [isLoginAPILoading, setIsLoginAPILoading] = useState(false);
  const navigate = useNavigate();
  const [forgotFormData, setForgotFormData] = useState({
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    otp: "",
  });
  const [isResetPaaswordLoading, setIsResetPasswordLoading] = useState(false);
  const [verifyData, setVerifyData] = useState({});

  const handleForgotInputChange = (e) => {
    const { name, value } = e.target;
    setForgotFormData((prev) => ({ ...prev, [name]: value }));
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    setIsForgotPasswordLoading(true);
    if (formData?.email !== "") {
      try {
        const response = await axios.post(
          `${REACT_APP_API_KEY}/forgot-password`,
          { email: formData?.email }
        );
        setForgotData(response.data);
        setForgotFormData((prev) => ({
          ...prev,
          email: response.data.email,
        }));
      } catch (error) {
        console.log(error, "error");
        setForgotData(error.response.data);
        toast(error?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
      setIsForgotPasswordLoading(false);
    } else {
      toast("Please enter your eamil", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setIsForgotPasswordLoading(false);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setIsResetPasswordLoading(true);
    try {
      if (forgotFormData?.newPassword === forgotFormData?.confirmNewPassword) {
        const response = await axios.post(
          `${REACT_APP_API_KEY}/reset-password`,
          forgotFormData
        );
        console.log(response.data, "res");
        setVerifyData(response.data);
        if (response.data.status === 200) {
          setForgotData({});
        }
      } else {
        toast("New password and confirm new password are not same", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error, "error");
      setVerifyData(error.response.data);
      toast(error?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    setIsResetPasswordLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoginAPILoading(true);
    try {
      const response = await fetch(`${REACT_APP_API_KEY}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      const res = await response.json();
      setUserDetails(res);
      if (res?.message === "user logged in successfully") {
        setIsLoggedIn(true);
        setActiveNavbar("");
        navigate("/");
      } else {
        toast(res?.message || "error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log("Error logging in:", error);
    }
    setIsLoginAPILoading(false);
  };

  return {
    setForgotFormData,
    forgotData,
    forgotPassword,
    resetPassword,
    verifyData,
    forgotFormData,
    handleForgotInputChange,
    isForgotPasswordLoading,
    isResetPaaswordLoading,
    isLoginAPILoading,
    handleSubmit,
  };
};

export default useForgotPassword;
