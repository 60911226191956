/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import useDebounceQuery from "../common/useDebounceQuery";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useGetUsers = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    searchInput: undefined,
  });
  const { searchInput } = filters;
  const { query = "", debounceQuery } = useDebounceQuery();

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    debounceQuery(searchInput);
  }, [debounceQuery, searchInput]);

  const getUserList = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_KEY}/nyaay-users`, {
        params: {
          q: query || undefined,
          pageNumber: currentPage,
          pageSize: 10,
        },
        // withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      setUserList(response?.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err || "Opps Somthing went wrong");
      if(err.response.status === 401){
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, [query, currentPage]);

  return {
    userList,
    isLoading,
    filters,
    setFilters,
    getUserList,
    currentPage,
    setCurrentPage,
    setIsLoading,
  };
};

export default useGetUsers;
