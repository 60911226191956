import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import Signup from "./Signup";
import Login1 from "./Login1";
import NyaayLogo from "../../Images/Main.png";

// const nyaayImage =
//   "https://static.wixstatic.com/media/fcfcd3_a9d3ee1043364ca181bf02b4dbaaae67~mv2.png/v1/fill/w_912,h_1024,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/fcfcd3_a9d3ee1043364ca181bf02b4dbaaae67~mv2.png";

const LandingPage = () => {
  const [showSignUpPage, setShowSignUpPage] = useState(false);
  return (
    <div className="sm:flex w-full h-screen">
      <div className="sm:w-1/2 flex justify-center items-center">
        <img className="w-3/4 h-3/4" src={NyaayLogo} alt="nyaayImage" />
      </div>
      <div className="bg-white sm:w-1/2 flex flex-col  items-center relative overflow-auto">
        {/* <span className="text-center text-2xl text-[#4D64DCB9] font-semibold my-2">
          Transforming the Judicial Process with AI-Powered Solutions
        </span> */}
        {showSignUpPage ? (
          <Signup setShowSignUpPage={setShowSignUpPage} />
        ) : (
          <Login1 setShowSignUpPage={setShowSignUpPage} />
        )}
      </div>
    </div>
  );
};

export default LandingPage;
