import React from "react";
import Modal from "../../common-components/Modal";
import { Oval } from "react-loader-spinner";
import ViewDefectUploadedDocument from "./ViewDefectUploadedDocument";
import useGetSelectedRectifiedData from "./hooks/useGetSelectedRectifiedData";
import useGetRectifiedPage from "./hooks/useGetRectifiedPage";

const ShowRectifiedData = ({
  rectifyId,
  showRectifiedModal,
  setShowRectifiedModal,
  getSelectedData,
}) => {
  const { selectedRectifiedData } = useGetSelectedRectifiedData({ rectifyId });
  const { defectFounds = [] } = selectedRectifiedData || {};
  const { isPdfLoading, pdfUrl } = useGetRectifiedPage({
    rectifyId,
  });

  return (
    <div>
      <Modal
        showModal={showRectifiedModal}
        closeModal={() => {
          setShowRectifiedModal(false);
          getSelectedData();
        }}
        modalWidth="w-[90%]"
        modalHeight="h-[96%]"
      >
        <div className="flex justify-center -mt-5 text-base font-medium">
          Rectified Defect Detection
        </div>
        {defectFounds.length === 0 || defectFounds === undefined ? (
          <div className="mt-12 flex justify-center items-center">
            <Oval
              height={120}
              width={120}
              color="Indigo"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="Indigo"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div className="sm:flex justify-between w-full m-4">
            <div className="sm:w-1/2">
              {isPdfLoading ? (
                <div className="mt-12 flex justify-center items-center">
                  <Oval
                    height={120}
                    width={120}
                    color="Indigo"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="Indigo"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <ViewDefectUploadedDocument pdfUrl={pdfUrl} />
              )}
            </div>
            <div className=" w-1/2  px-10">
              {defectFounds.map((item, index) => {
                const isCritical = [
                  "Blurred Page",
                  "Missing Page Number",
                  "Signature Absent",
                  "NOT in OCR format",
                ].includes(item);
                const bgColor = isCritical ? "bg-red-500" : "bg-blue-500";
                return (
                  <div
                    key={index}
                    className={`${bgColor} w-fit flex-shrink-0  font-normal text-sm px-3 py-1 rounded-3xl mx-1 mb-2 text-white`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ShowRectifiedData;
