import React from "react";
import InputLayout from "./InputLayout";
function GroundOfAppeal({
  isEditable,
  groundsOfAppealDetails = {},
  setGroundsOfAppealDetails = () => {},
}) {
  return (
    <div className="flex justify-center pb-4">
      <form
        className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
        // onSubmit={handleSaveDraft}
      >
        <div className="flex">
          <div className="text-[#192839] text-base font-semibold">
            Ground of Appeal
          </div>
        </div>
        <hr className="my-4"></hr>

        <InputLayout
          isEditable={isEditable}
          petitionerData={groundsOfAppealDetails}
          setPetitionerData={setGroundsOfAppealDetails}
        />
      </form>
    </div>
  );
}

export default GroundOfAppeal;
