import React, { useState, useEffect } from "react";
import useGetTextDataById from "./useGetTextDataById";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faSave } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import UpdateTextModal from "./UpdateTextModal";
import useUpdateTextData from "./useUpdateTextData";

const AudioPlayer = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const { speechData } = useGetTextDataById({ id });
  const { data } = speechData || {};
  const { _source } = data || {};
  const { filename = "--" } = _source || {};
  const { transcript = "--" } = _source || {};
  const [transcriptFront, setTranscriptFront] = useState("");
  const [changeTrans, setChangeTrans] = useState(true);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const { handleUpdateTranscript }=useUpdateTextData();

  const audioUrl = `https://d32zqp4i13o9iu.cloudfront.net/Real_Time/Processed/${id}`;

  useEffect(() => {
    setTranscriptFront(transcript);
  },[transcript]);



  const handleUpdateText = (event) => {
    setTranscriptFront(event.target.value);
    setChangeTrans(false);
  };

  return (
    <div className="px-6 py-2 flex flex-col h-screen">
      <ToastContainer />
      <div className="flex justify-between items-center">
        <button onClick={() => navigate("/RealTimeSTT")}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-lg font-semibold">{filename}</h1>
      </div>
      <hr className="my-2" />
      <div className="flex flex-1">
        <div className="flex-1 flex flex-col">
          <div className="w-full">
            <audio id="myAudio" className="w-full" autoPlay controls>
              <source src={audioUrl} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          </div>
          <textarea
          className="text-[#192839] font-normal text-sm p-2 mt-5 overflow-y-auto border-[#6C849D2E] rounded border focus:outline-none flex-1 resize-none"
          value={transcriptFront}
          onChange={handleUpdateText}
        ></textarea>
          <button
            onClick={() => setShowUpdateModel(true)}
            className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={changeTrans}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Update
          </button>
          <ToastContainer />
          {showUpdateModel ? (
            <UpdateTextModal
              showUpdateModel={showUpdateModel}
              setShowUpdateModel={setShowUpdateModel}
              handleUpdateTranscript={handleUpdateTranscript}
              id={id}
              transcriptFront={transcriptFront}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
