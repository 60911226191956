import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const MobileHeader = () => {
  const navigate = useNavigate();
  const [showNavigation, setShowNavigation] = useState(false);
  return (
    <div>
      <div className="flex w-full justify-between bg-white shadow-md">
        <div className="p-2">
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => setShowNavigation((prev) => !prev)}
          />
        </div>
        <div className="flex p-2">
          <img
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd3d6901b3eee8fef03e9158a6c7e1ca6.cdn.bubble.io%2Ff1687799868019x693538119190973300%2F01.png?w=384&h=80&auto=compress&dpr=1&fit=max"
            alt="NyaayLogo"
            className="h-5 w-15"
            onClick={() => navigate("/Home")}
          />
        </div>
      </div>
      {showNavigation ? (
        <div className="w-1/2">
          <Navbar setShowNavigation={setShowNavigation} />
        </div>
      ) : null}
    </div>
  );
};

export default MobileHeader;
