import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadModal from "../common/FileUploadModal";
import useUploadRectifiedPage from "./hooks/useUploadRectifiedPage";
import { formatDateTime } from "./DefectTab";
import moment from "moment";
import ShowRectifiedData from "./ShowRectifiedData";

const OPTIONS = [
  { label: "Signature Absent", value: "Signature Absent" },
  { label: "Blurred Page", value: "Blurred Page" },
  { label: "Missing Page Number", value: "Missing Page Number" },
  { label: "Ink Mark", value: "Ink Mark" },
  { label: "vern_lang_hin", value: "vern_lang_hin" },
  { label: "vern_lang_mar", value: "vern_lang_mar" },
  { label: "vern_lang_tam", value: "vern_lang_tam" },
  { label: "vern_lang_ben", value: "vern_lang_ben" },
  { label: "vern_lang_kan", value: "vern_lang_kan" },
  { label: "vern_lang_ori", value: "vern_lang_ori" },
  { label: "vern_lang_tel", value: "vern_lang_tel" },
  { label: "vern_lang_guj", value: "vern_lang_guj" },
  { label: "vern_lang_mal", value: "vern_lang_mal" },
  { label: "vern_lang_pan", value: "vern_lang_pan" },
  { label: "vern_lang_asm", value: "vern_lang_asm" },
];

const PageLevelDefects = ({
  data = [],
  setSelectedDefectsPage,
  handleRemoveClick,
  selectedDefectsPage,
  handleSelectDefectChange,
  handleRemarksChange,
  updateData,
  id,
  getSelectedData,
  setIsDisabled,
}) => {
  // const dataExcludingPages1And2 = data.filter(
  //   (item) => item.defectPage !== 1 && item.defectPage !== 2
  // );
  const { selectedCourt } = updateData || {};

  const filteredData = data?.find(
    (item) => item.defectPage === selectedDefectsPage
  );
  const defectFounds = filteredData?.defectFounds || [];
  const filteredDefectOptions = OPTIONS.filter(
    (item) => !defectFounds?.some((defect) => defect === item.label)
  );

  const showToast = () => {
    setSelectedDefectsPage(null);
    setIsDisabled(false);
    toast.info("To Save it permanently please Click on Update", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  const [showPageLevelDefect, setShowPageLevelDefect] = useState(false);
  const [showUploadRectifiedModal, setShowUploadRectifiedModal] =
    useState(false);

  const [rectifiedPageNo, setRectifiedPageNo] = useState(null);

  const { onRectifiedUpload, isRectifiedUploading } = useUploadRectifiedPage({
    id,
    rectifiedPageNo,
    selectedCourt,
    getSelectedData,
  });

  const [showRectifiedModal, setShowRectifiedModal] = useState(false);
  const [rectifyId, setRectifyId] = useState(null);

  const handleShowRectifyData = (id) => {
    setRectifyId(id);
    setShowRectifiedModal(true);
  };

  return (
    <div
      className="bg-white shadow my-4 mx-1 rounded p-2"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-[#40566D] font-semibold">Page level Defects</h1>
        <button
          className="hover:border-2 h-8 border gap-1 px-2 border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center shadow-md"
          onClick={() => setShowPageLevelDefect((prev) => !prev)}
        >
          {showPageLevelDefect ? "Collapse All" : "View All"}
          <FontAwesomeIcon
            icon={showPageLevelDefect ? faAngleUp : faAngleDown}
          />
        </button>
      </div>
      <ToastContainer />
      {showPageLevelDefect &&
        //dataExcludingPages1And2.map((item) => {
        data.map((item) => {
          return (
            <div
              key={item?.defectPage}
              style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
              className="p-2 rounded-md my-2"
            >
              <div className="flex items-center justify-between">
                <h1 className="text-[#40566D] text-sm font-semibold">
                  Page No : {item.defectPage}
                </h1>
                <div className="flex items-center gap-5 mt-2 text-sm">
                  {item.defectFounds.some((defect) =>
                    [
                      "Blurred Page",
                      "Missing Page Number",
                      "Signature Absent",
                      "NOT in OCR format",
                    ].includes(defect)
                  ) && (
                    <div>
                      <button
                        onClick={() => {
                          setRectifiedPageNo(item.defectPage);
                          setShowUploadRectifiedModal(true);
                        }}
                        className="active:bg-[#5E48FC] w-44 h-8 px-3 disabled:border-[#C6C7C9] flex items-center hover:border-2 hover:border-[#5E48FC] border justify-center disabled:border disabled:bg-[#F9FAFB] disabled:text-[#A2A3A7]  border-[#5E48FC] item-center py-2 rounded-lg text-sm text-[#5E48FC]"
                      >
                        Upload Rectified Page
                      </button>
                    </div>
                  )}
                  <button
                    onClick={() =>
                      setSelectedDefectsPage(
                        selectedDefectsPage === item.defectPage
                          ? null
                          : item.defectPage
                      )
                    }
                    className="active:bg-[#5E48FC] h-8 w-38 px-3 disabled:border-[#C6C7C9] flex items-center hover:border-2 hover:border-[#5E48FC] border justify-center disabled:border disabled:bg-[#F9FAFB] disabled:text-[#A2A3A7]  border-[#5E48FC] item-center py-2 rounded-lg text-sm text-[#5E48FC]"
                  >
                    {selectedDefectsPage === item.defectPage
                      ? "Cancel"
                      : "Add/Remove Defects"}
                  </button>
                </div>
              </div>

              <div className="mt-1 flex flex-wrap">
                {item.defectFounds.map((defect) => {
                  const isCritical = [
                    "Blurred Page",
                    "Missing Page Number",
                    "Signature Absent",
                    "NOT in OCR format",
                  ].includes(defect);

                  const isRectified = item?.rectifyDefects?.includes(defect);

                  // Determine background color based on whether the defect is rectified or critical
                  let bgColor = "";
                  if (item.rectifyDefects === undefined) {
                    bgColor = isCritical ? "bg-red-500" : "bg-blue-500";
                  } else {
                    bgColor = !isRectified
                      ? "bg-gray-500"
                      : isCritical
                      ? "bg-red-500"
                      : "bg-blue-500";
                  }

                  return (
                    <div
                      key={defect.id}
                      className={`${bgColor} font-normal text-sm px-3 py-1 rounded-3xl m-1 text-white`}
                    >
                      {defect}
                      <button
                        className="ml-2"
                        onClick={() =>
                          handleRemoveClick(defect, item.defectPage)
                        }
                      >
                        <FontAwesomeIcon icon={faXmark} color="white" />
                      </button>
                    </div>
                  );
                })}
              </div>
              {item.rectifyVersion === undefined ? null : (
                <div>
                  <h1 className="text-[#40566D] my-2 text-sm font-semibold">
                    Rectified Version :
                  </h1>
                  <div>
                    <div className="my-2 border text-sm rounded-lg">
                      <div className="flex text-sm bg-gray-200 py-2 ps-2">
                        <div className="w-[80%]">Rectify File</div>
                        <div className="w-[20%]">Rectified At</div>
                      </div>
                      {item?.rectifyVersion.reverse().map((rectify, index) => {
                        const { rectifyId, filename, insertTime } =
                          rectify || {};
                        return (
                          <div
                            className="flex py-1  last:border-none border-b"
                            key={index}
                          >
                            <button
                              onClick={() => handleShowRectifyData(rectifyId)}
                              className="flex py-1 hover:underline text-[#5E48FC] flex-wrap ms-2 items-center last:border-none w-[74%]"
                            >
                              {filename || rectifyId.substring(37)}
                            </button>
                            <div className="w-[26%]">
                              {formatDateTime(insertTime)} |
                              {moment(insertTime).format("HH:mm")}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {selectedDefectsPage === item.defectPage && (
                <div className="py-2">
                  <div className="flex justify-end">
                    <select
                      onChange={handleSelectDefectChange}
                      value=""
                      className="border text-[#40566D] text-sm py-1 rounded focus:outline-none"
                    >
                      <option value="" disabled>
                        Select a defect to add
                      </option>
                      {filteredDefectOptions?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="text-[#40566D] text-sm font-semibold">
                      Remarks:
                    </label>
                    <textarea
                      name="remarks"
                      value={item?.remarks || ""}
                      onChange={handleRemarksChange}
                      className="border font-normal text-[#40566D] text-sm rounded p-2 focus:outline-none w-full"
                      rows="2"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="border px-3 py-1 rounded-lg shadow-md text-sm bg-[#5E48FC] text-white"
                      onClick={showToast}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {showUploadRectifiedModal ? (
        <FileUploadModal
          showFileUploadModal={showUploadRectifiedModal}
          setShowFileUploadModal={setShowUploadRectifiedModal}
          handleFileUpload={onRectifiedUpload}
          isLoading={isRectifiedUploading}
          heading="Rectified Page"
          subHeading="Upload the file for Defect Rectification"
        />
      ) : null}
      {showRectifiedModal ? (
        <ShowRectifiedData
          rectifyId={rectifyId}
          showRectifiedModal={showRectifiedModal}
          setShowRectifiedModal={setShowRectifiedModal}
          getSelectedData={getSelectedData}
        />
      ) : null}
    </div>
  );
};

export default PageLevelDefects;
