import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import ConfirmModal from "./ConfirmModal";
import { startCase } from "lodash";
import useExtractionUpdate from "./useExtractionUpdate";
import ViewExtractionDocument from "./ViewExtractionDocument";
import { UserContext } from "../Routing";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

function EntityExtraction() {
  const userState = useContext(UserContext);
  const { userDetails = {} } = userState || {};
  const { userData = {} } = userDetails;
  const { authRole } = userData;
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};

  const {
    isEditable,
    handleInputChange,
    handleEdit,
    handleSave,
    handleAddField,
    handleRemoveField,
    formatedData,
    handleExtractionUpdate,
  } = useExtractionUpdate({ id });

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="py-2 mx-4">
        <ToastContainer />
        <div className="flex justify-between">
          <button onClick={() => navigate("/EntityExtraction")}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          <h1 className="text-[#40566D] text-xl font-semibold">
            Information Extraction
          </h1>
        </div>
        <hr className="sm:-mx-4 mt-2"></hr>
        <ToastContainer />
        <div className="sm:flex justify-between w-full my-4">
          <div className="sm:w-1/2 h-full">
            <ViewExtractionDocument fileName={id} />
          </div>
          <div className="px-4 ml-2 mt-4 sm:mt-0 sm:w-1/2 overflow-y-auto sm:border-l-2 border-gray-900 max-h-screen">
            <div className="flex flex-wrap justify-between items-center">
              <div className="p-1 text-base text-[#192839">
                Digitization Result
              </div>
              {authRole !== "User" ? (
                <div className="ml-auto">
                  <button
                    className="bg-[#5E48FC] px-6 py-2 text-white border-[#5E48FC] border shadow-xl rounded-lg font-semibold text-xs"
                    onClick={() => setShowModal(true)}
                  >
                    Save
                  </button>
                </div>
              ) : null}
            </div>
            <hr className="my-2"></hr>

            <form className="bg-white p-2 border rounded shadow">
              <div className="flex justify-between">
                <div>
                  <h1 className="text-[#192839] font-normal text-base">
                    Extraction details
                  </h1>
                </div>
                {authRole !== "User" ? (
                  <div className="flex justify-end mb-4">
                    {isEditable ? (
                      <button
                        onClick={handleSave}
                        className="text-[#2950DA] px-4 py-2 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        onClick={handleEdit}
                        className="bg-[#2950DA] text-white px-6 py-2 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
                      >
                        Edit
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
              {Object.entries(formatedData).map(([key, value]) => (
                <div className="mb-3 bg-white shadow rounded p-2" key={key}>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block startcase tracking-wide text-[#40566D] text-xs mb-2 font-semibold">
                      {startCase(key).includes("Summary") ? "Summary" : startCase(key)}
                    </label>
                  </div>
                  {Array.isArray(value) ? (
                    <>
                      {value.map((item, index) => (
                        <div className="flex items-center" key={index}>
                          <input
                            className="appearance-none block w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                            type="text"
                            name={key}
                            value={item}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled={!isEditable}
                          />
                          {isEditable && (
                            <button
                              className="bg-[#D92D20] mx-2 mb-3 px-4 py-3.5 text-white rounded-md text-xs"
                              onClick={(e) => handleRemoveField(index, e, key)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                          )}
                        </div>
                      ))}
                      {isEditable ? (
                        <div className="flex items-center justify-center">
                          <button
                            className="text-[#2950DA] px-2.5 py-2 border-[#2950DA] border rounded-md font-semibold mt-2 text-xs"
                            onClick={(e) => handleAddField(e, key)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="ms-1"> Add</span>
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : key === "FinalJudgement" || key === "DetailedSummary" ? (
                    <div className="flex items-center">
                      <textarea
                        className="appearance-none block w-full disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded py-2 px-4 mb-3 leading-tight focus:outline-none bg-white"
                        type="text"
                        name={key}
                        rows={8}
                        defaultValue={value}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <input
                        className="appearance-none block w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                        type="text"
                        name={key}
                        defaultValue={value}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                      />
                    </div>
                  )}
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
      {showModal ? (
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleExtractionUpdate={handleExtractionUpdate}
          id={id}
        />
      ) : null}
    </>
  );
}

export default EntityExtraction;