import React, { useState } from "react";
import useGetSelectedSimilarity from "./useGetSelectedSimilarity";
import ViewSimilarityDoc from "./ViewSimilarityDoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import RenderTooltipComponent from "../../common-components/RenderTooltipComponent";

const SelectedSimilarity = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const { similarityDoc } = useGetSelectedSimilarity({ id });
  const { top_k = [] } = similarityDoc || {};
  const [showDoc, setShowDoc] = useState("");
  const navigate = useNavigate();

  return (
    <div className="py-2 px-6">
      <div className="flex justify-between">
        <button onClick={() => navigate("/SimilaritySearch")}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-lg font-semibold">
          Similarity Result
        </h1>
        <div className="text-sm font-medium text-[#40566D">
          {id.replace(/^[^_]*_/g, "")}
        </div>
      </div>
      <hr className="sm:-mx-6 mt-2"></hr>
      <div className="sm:flex justify-between w-full my-2">
        <div className="sm:w-1/2 h-full">
          <ViewSimilarityDoc fileName={id} />
        </div>
        <div className="h-full sm:mt-0 sm:w-1/2 overflow-y-auto">
          <div className="flex text-sm mt-2 mb-3 font-semibold text-[#40566D">
            Similar Documents
          </div>
          <hr></hr>
          {top_k.map((item, index) => {
            return (
              <div>
                <div className="my-2 flex justify-between border p-2 rounded">
                  <div className="text-[#40566D] hover:underline hover:text-[#2950DA] ml-1">
                    {index + 1}.
                    <button
                      className="text-[#40566D] hover:underline hover:text-[#2950DA] ml-1"
                      onClick={() => setShowDoc(item)}
                    >
                      <RenderTooltipComponent
                        content={item.replace(/^[^_]*_/g, "")}
                        maxLength={50}
                        maxWidth={900}
                      />
                    </button>
                  </div>
                  {showDoc === item ? (
                    <button
                      onClick={() => setShowDoc(null)}
                      className="bg-[#2950DA] px-2 py-1 text-white border-[#2950DA] border shadow-xl rounded-md font-semibold text-xs"
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowDoc(item)}
                      className="bg-[#2950DA] px-2 py-1 text-white border-[#2950DA] border  shadow-xl rounded-md font-semibold text-xs"
                    >
                      View
                    </button>
                  )}
                </div>
                {showDoc === item ? (
                  <div className="w-full h-full mt-2 ">
                    <ViewSimilarityDoc fileName={item} />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectedSimilarity;
