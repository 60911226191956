import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
const { REACT_APP_API_KEY } = process.env;

function ViewExtractionDocument({ fileName }) {
  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(true);

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    fetch(`${REACT_APP_API_KEY}/getExtractionPdf?filename=${fileName}`, {
      // credentials: "include",
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setIsPdfLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the PDF:", error);
      });
  }, [fileName, token]);

  return (
    <div className="flex justify-center items-center">
      {isPdfLoading ? (
        <div className="mt-12">
          <Oval
            height={120}
            width={120}
            color="Indigo"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="Indigo"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="w-full h-full">
          <iframe title="PDF Viewer" src={pdfUrl} className="h-screen w-full" />
        </div>
      )}
    </div>
  );
}

export default ViewExtractionDocument;
