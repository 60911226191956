export default function FilteredSegment(props) {
  return (
    <div className="mb-2">
      <input
        id={props.start}
        type="checkbox"
        defaultChecked={props.ticked}
        name={props.start}
        value={props.start}
        onChange={() => props.toggleThisSegment(props.start)}
      />
      <label htmlFor={props.start}>
        <span className="text-blue-800 ml-3">
          {(props.start / 100)?.toFixed(2)}
        </span>
        <span className="ml-3 text-sm text-[#192839">{props.text}</span>
      </label>
    </div>
  );
}
