import React, { useState, useEffect, useRef } from "react";
import useGetSpeechDataById from "./useGetSpeechDataById";
import _debounce from "lodash/debounce";
import { startCase } from "lodash";
import Word from "./Word";
import UpdateSpeakerModal from "./UpdateSpeakerModal";
import { ToastContainer } from "react-toastify";
import useUpdateTextData from "./useUpdateTextData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const VideoPlayer = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const { speechData } = useGetSpeechDataById({ id });
  const { data } = speechData || {};
  const { _source } = data || {};
  const { segments = [], updates = [], filename = "--" } = _source || {};
  const [transcript, setTranscript] = useState([]);
  const [fetchedTranscript, setFetchedTranscript] = useState(segments);
  const textareaRef = useRef(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [spkFilteredList, setSpkFilteredList] = useState([]);
  const handleUpdateTranscript = useUpdateTextData();

  useEffect(() => {
    if (updates && updates.length > 0) {
      const latestUpdate = updates[updates.length - 1];
      setFetchedTranscript(latestUpdate.segments);
    } else {
      setFetchedTranscript(segments);
    }
  }, [segments, updates]);

  const transcriptString = fetchedTranscript?.map((block) => (
    <Word
      id={block.start}
      text={block.text}
      key={block.start}
      speaker={block.speaker}
      editText={editText}
      editSpeakerSubmit={editSpeakerSubmit}
      goToTimestamp={goToTimestamp}
    />
  ));

  function editText(id, newText) {
    const editedTranscript = fetchedTranscript.map((block) => {
      if (id === block.start) {
        return { ...block, text: newText };
      }
      return block;
    });
    setFetchedTranscript(editedTranscript);
  }

  function editSpeakerSubmit(speaker, newSpeaker) {
    const thisSpeakerList = fetchedTranscript
      .filter((block) => block.speaker === speaker)
      .map((block) => ({ ...block, speaker: newSpeaker }));

    setSpkFilteredList(thisSpeakerList);
    setShowUpdateModal(true);
  }

  function goToTimestamp(timestamp) {
    const video = document.getElementById("myVideo");
    video.currentTime = timestamp;
    video.play();
  }

  const updateText = (timestamp, words) => {
    setTranscript((prevTranscript) => {
      const newTranscriptBlocks = words.filter(
        (block) => block.start <= timestamp
      );
      const newTranscript = [];
      newTranscriptBlocks.forEach(({ speaker, text }) => {
        const lastBlock = newTranscript[newTranscript.length - 1];

        if (!lastBlock || lastBlock.speaker !== speaker) {
          newTranscript.push({ speaker, words: [text] });
        } else {
          lastBlock.words?.push(text);
        }
      });
      return newTranscript;
    });
  };

  const debouncedUpdateText = _debounce(updateText, 100);

  useEffect(() => {
    const video = document.getElementById("myVideo");
    const handleTimeUpdate = () => {
      if (fetchedTranscript) {
        debouncedUpdateText(video.currentTime, fetchedTranscript);
      }
    };
    video.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [fetchedTranscript, transcript, debouncedUpdateText]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (textareaRef.current) {
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [transcript]);

  const renderTranscript = () => {
    return transcript?.map((block, index) => (
      <div key={index} className="flex">
        <span className="shrink-0 font-medium">
          {startCase(block.speaker)} :{" "}
        </span>
        <span className="rounded px-1 inline-block mx-2">
          {block.words?.join(" ")}{" "}
        </span>
      </div>
    ));
  };

  const videoUrl = `https://d32zqp4i13o9iu.cloudfront.net/speech-to-text/Processed/${id}`;
  //   const currentTime = new Date().toISOString();
  //   const dataToSend = {
  //     user: email,
  //     updateTime: currentTime,
  //     segments: fetchedTranscript,
  //   };

  //   try {
  //     const response = await fetch(
  //       `${REACT_APP_API_KEY}/updateTextData/${id}`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         params: {
  //           id: id,
  //         },
  //         body: JSON.stringify(dataToSend),
  //       }
  //     );
  //     await response.json();
  //     toast.info("Data is successfully updated", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 2000,
  //     });
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  return (
    <div className="px-6 py-2">
      <ToastContainer />
      <div className="flex justify-between">
        <button onClick={() => navigate("/SpeechToText")}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-lg font-semibold">{filename}</h1>
      </div>
      <hr className="sm:-mx-6 my-2"></hr>
      <div className="grid grid-cols-3 gap-x-4">
        <div className="col-span-2">
          <div className="w-full">
            <video id="myVideo" className="w-full" autoPlay controls>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            ref={textareaRef}
            className=" text-[#192839] font-normal text-sm p-2 mt-5 overflow-y-auto border-[#6C849D2E] rounded border focus:outline-none h-60 resize-none"
          >
            {renderTranscript()}
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <div className="flex justify-end mb-2">
              <button
                className="bg-[#5E48FC] px-6 py-1.5 ml-4 text-xs rounded shadow-md text-white"
                onClick={() => handleUpdateTranscript(id, fetchedTranscript)}
              >
                Save
              </button>
            </div>
            <div className="font-normal text-[#192839] text-sm row-span-1 p-2 overflow-y-auto border-[#192839] rounded border outline-none h-screen resize-none">
              {transcriptString}
            </div>
          </div>
        </div>
      </div>
      {showUpdateModal ? (
        <UpdateSpeakerModal
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          fetchedTranscript={fetchedTranscript}
          spkFilteredList={spkFilteredList}
          setSpkFilteredList={setSpkFilteredList}
          setFetchedTranscript={setFetchedTranscript}
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default VideoPlayer;
