import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const UpdatePassword = ({ setPasswordModal }) => {
  return (
    <div className="bg-white shadow-md rounded absolute z-60 w-fit">
      <div className="flex justify-between p-4">
        <h1>Update Password</h1>
        <div className="">
          <button className="ml-2" onClick={() => setPasswordModal(false)}>
            <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
          </button>
        </div>
      </div>
      <hr className="" />
      <div className="p-4">
        <div className="mb-3">
          <label>Current Password</label>
          <div>
            <input
              type="password"
              placeholder="Enter your new password"
              required
              className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
            />
          </div>
        </div>
        <div className="mb-4">
          <label>New Password</label>
          <input
            type="password"
            name="password"
            value=""
            // onChange={}
            placeholder="Enter your new password"
            required
            className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div>
          <label>Confirm Password</label>
          <input
            type="password"
            name="password"
            value=""
            // onChange={}
            placeholder="Enter your new password"
            required
            className="w-full p-1.5 border rounded-md mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
      </div>

      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-indigo-700 text-white px-2 py-0.5 rounded">
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdatePassword;
