import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../Routing";
const { REACT_APP_API_KEY } = process.env;

const useGetEntityExtraction = ({ getExistingData = () => {} }) => {
  const userState = useContext(UserContext);
  const { userDetails = {} } = userState || {};
  const { userData = {} } = userDetails || {};
  const { name, email } = userData || {};
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [isUplaodLoading, setIsUploadLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const handleExtractionFileUpload = async (selectedFiles) => {
    setIsUploadLoading(true);
    setShowFileUpload(false);
    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("email", email);
    formData.append("name", name);
    try {
      const response = await axios.post(
        `${REACT_APP_API_KEY}/entityExtraction/uploadFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
          },
          // withCredentials: true,
        }
      );
      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        getExistingData();
      }, 800);
    } catch (error) {
      console.log(error, "error");
      toast.error("Please upload valid file", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    setIsUploadLoading(false);
  };

  const uploadNewExtractionDocument = () => {
    setShowFileUpload(true);
  };
  return {
    showFileUpload,
    handleExtractionFileUpload,
    uploadNewExtractionDocument,
    isUplaodLoading,
  };
};

export default useGetEntityExtraction;
