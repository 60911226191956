import { useState } from "react";
import UpdateTextModal from "./UpdateTextModal";

export default function Word(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(props.text);
  const [newSpeaker, setNewSpeaker] = useState(props.speaker);
  const [isSpeakerEditing, setIsSpeakerEditing] = useState(false);
  const [showTextModal, setShowTextModal] = useState(false);

  function handleTextChange(e) {
    setNewText(e.target.value);
  }

  function handleTextSubmit() {
    props.editText(props.id, newText);
    setIsEditing(false);
    setShowTextModal(false);
  }

  function handleTimeStampClick() {
    props.goToTimestamp(props.id);
  }

  function handleSpeakerChange(e) {
    setNewSpeaker(e.target.value);
  }

  function handleSpeakerSubmit() {
    props.editSpeakerSubmit(props.speaker, newSpeaker);
    setIsSpeakerEditing(false);
  }

  const editingTextArea = (
    <textarea
      defaultValue={props.text}
      className="appearance-none block w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded p-1 mb-3 leading-tight focus:outline-none bg-white"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          setShowTextModal(true);
        }
      }}
      onChange={handleTextChange}
      onBlur={() => setShowTextModal(true)}
      autoFocus
      onFocus={(e) => {
        e.target.setSelectionRange(
          e.target.value.length,
          e.target.value.length
        );
      }}
    />
  );

  const textSpan = (
    <span
      className="rounded px-1 inline-block"
      tabIndex={0}
      onFocus={() => setIsEditing(true)}
    >
      {props.text}
    </span>
  );

  const editingSpeaker = (
    <textarea
      defaultValue={props.speaker}
      className="w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border rounded focus:outline-none bg-white"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleSpeakerSubmit();
        }
      }}
      onChange={handleSpeakerChange}
      onBlur={handleSpeakerSubmit}
      autoFocus
      onFocus={(e) => {
        e.target.setSelectionRange(
          e.target.value.length,
          e.target.value.length
        );
      }}
    />
  );

  const speakerSpan = (
    <span
      className="rounded px-1 inline-block"
      tabIndex={0}
      onFocus={() => setIsSpeakerEditing(true)}
    >
      {props.speaker}
    </span>
  );

  return (
    <div className="text-sm pt-2 pr-2 flex">
      <div className="inline-block text-blue-800">
        <button onClick={handleTimeStampClick}>
          {(props.id / 100)?.toFixed(2)}
        </button>
      </div>
      <div className="w-full inline-block ml-3" id={props.id}>
        <div>{isSpeakerEditing ? editingSpeaker : speakerSpan}:</div>
        <div>{isEditing ? editingTextArea : textSpan}</div>
      </div>
      {showTextModal ? (
        <UpdateTextModal
          showTextModal={showTextModal}
          setShowTextModal={setShowTextModal}
          handleEdit={handleTextSubmit}
          setIsEditing={setIsEditing}
        />
      ) : null}
    </div>
  );
}
