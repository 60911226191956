import axios from "axios";
import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
// import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useEfileHistory = ({ id }) => {
  const userState = useContext(UserContext);
  const {
    // setShowNavigation = () => {},
    setIsLoggedIn = () => {},
  } = userState || {};
  const navigate = useNavigate();

  const [updatedData, setUpdatedData] = useState({});
  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getEfilingData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getEfilingDataById`,
        {
          params: { id: id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUpdatedData(response.data._source);
      // Check if the status is "Completed"
      if (response.data._source.status === "Completed") {
        return true; // Indicate that the status is completed
      }
    } catch (err) {
      console.error(err || "Oops something went wrong");
      if (err.response && err.response.status === 401) {
        console.log("Invalid Token! Please login again");
        navigate("/");
        setIsLoggedIn(false);
      }
    }
    return false; // Indicate that the status is not completed
  }, [id, token, setIsLoggedIn, navigate]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isCompleted = await getEfilingData();
      if (isCompleted) {
        clearInterval(intervalId); // Stop calling the function if status is "Completed"
      }
    }, 5000); // Call every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [getEfilingData]);

  useEffect(() => {
    getEfilingData();
  }, [getEfilingData, id]);

  return {
    updatedData,
    setUpdatedData,
  };
};

export default useEfileHistory;
