export const SUPREME_COURT_DEFECT_LIST = [
  { label: "Missing Signature", value: "Missing Signature" },
  { label: "Blurred Page", value: "Blurred Page" },
  { label: "Vernacular Page", value: "Vernacular Page" },
  { label: "OCR defects", value: "OCR defects" },
  { label: "Proforma Defects", value: "Proforma Defects" },
  { label: "Index defects", value: "Index defects" },
  {
    label: "Special Leave Petition (SLP) defects",
    value: "Special Leave Petition (SLP) defects",
  },
  { label: "Affidavit defects", value: "Affidavit defects" },
  { label: "Annexures defects", value: "Annexures defects" },
  { label: "Vakalatnama defects", value: "Vakalatnama defects" },
  { label: "Memo of appearance defect", value: "Memo of appearance defect" },
  { label: "Memo of parties defect", value: "Memo of parties defect" },
  {
    label: "Non-mentioning of number of days of delay defect",
    value: "Non-mentioning of number of days of delay defect",
  },
];
