import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios"; // Ensure axios is imported
const { REACT_APP_API_KEY } = process.env;

const useGetRectifiedPage = ({ rectifyId }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(true);

  const getRectifiedPage = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      const res = await axios.get(
        `${REACT_APP_API_KEY}/getRectifiedPage?id=${rectifyId}`,
        {
          responseType: "blob", // This ensures the response is in blob format
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob); // Create a URL for the blob
      setPdfUrl(url);
      setIsPdfLoading(false);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    return false;
  }, [rectifyId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isCompleted = await getRectifiedPage();
      if (isCompleted) {
        clearInterval(intervalId); // Stop calling the function if status is "Completed"
      }
    }, 5000); // Call every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [getRectifiedPage]);

  useEffect(() => {
    getRectifiedPage();
  }, [rectifyId, getRectifiedPage]);

  return { isPdfLoading, pdfUrl };
};

export default useGetRectifiedPage;
