import React from "react";
import InputLayout from "./InputLayout";
function QuestionsOfLaw({
  // setActiveTab = () => {},
  // authRole,
  // data = {},
  isEditable,
  // setIsEditable,
  // setUpdatedData = () => {},
  questionsOfLawDetails = {},
  setQuestionsOfLawDetails = () => {},
}) {
  return (
    <div className="flex justify-center pb-4">
      <form
        className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
        // onSubmit={handleSaveDraft}
      >
        <div className="flex">
          <div className="text-[#192839] text-base font-semibold">
            Questions of Law
          </div>
          {/* {authRole !== "User" ? (
            <div>
              {isEditable ? (
                <button
                  onClick={handleCancel}
                  className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={handleEdit}
                  className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                >
                  Edit
                </button>
              )}
            </div>
          ) : null} */}
        </div>
        <hr className="my-4"></hr>

        <InputLayout
          isEditable={isEditable}
          petitionerData={questionsOfLawDetails}
          setPetitionerData={setQuestionsOfLawDetails}
        />

        {/* <div className="flex justify-center gap-4">
                    {authRole !== "User" ? (
                        <button
                            className="bg-green-700 hover:bg-green-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Save Draft
                        </button>
                    ) : null}
                    <button
                        className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => {
                            setActiveTab("Petitioner");
                        }}
                    >
                        Next
                    </button>
                </div> */}
      </form>
    </div>
  );
}

export default QuestionsOfLaw;