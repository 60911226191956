/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
import useDebounceQuery from "../common/useDebounceQuery";
import { toast } from "react-toastify";
const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

const useGetExistingSimSearch = () => {
  const [existingSimSearchData, setExistingSimSearchData] = useState([]);
  const [existingDataLoading, setEistingDataLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sortingColumn, setSortingColumn] = useState("uploadedAt");

  const [filters, setFilters] = useState({
    searchInput: undefined,
    dates: undefined,
  });

  const userState = useContext(UserContext);
  const { setShowNavigation = () => {}, setIsLoggedIn = () => {} } =
    userState || {};
  const navigate = useNavigate();

  const { searchInput, dates } = filters;
  const [startDate, endDate] = dates || [];
  const { query = "", debounceQuery } = useDebounceQuery();

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    debounceQuery(searchInput);
  }, [debounceQuery, searchInput]);

  const getExistingData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getSimilaritySearchData`,
        {
          params: {
            q: query || undefined,
            startDate: startDate,
            endDate: endDate,
            pageNumber: currentPage,
            pageSize: 10,
            sortingColumn: sortingColumn,
            order: order,
          },
          // withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingSimSearchData(response?.data);
      setEistingDataLoading(false);
    } catch (err) {
      console.error(err || "Opps Somthing went wrong");
      if (err.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
      } else {
        toast.error("Opps Somthing went wrong");
      }
      setEistingDataLoading(false);
    }
  };

  useEffect(() => {
    getExistingData();
  }, [query, startDate, endDate, currentPage, order, sortingColumn]);

  const handleFileNameClick = async (id) => {
    const encodedData = { id: id };
    const navigateURL = `${REACT_APP_BASE_URL}/SelectedSimilarity?data=${JSON.stringify(
      encodedData
    )}`;
    window.open(navigateURL, "_self");
  };

  return {
    existingSimSearchData,
    existingDataLoading,
    setEistingDataLoading,
    filters,
    setFilters,
    currentPage,
    setCurrentPage,
    setOrder,
    setSortingColumn,
    sortingColumn,
    order,
    getExistingData,
    handleFileNameClick,
  };
};

export default useGetExistingSimSearch;
